import ActivityActions from "actions/ActivityActions";

const {
  FETCH_ACTIVITY_LIST_START,
  FETCH_ACTIVITY_LIST_DONE,
  FETCH_ACTIVITY_LIST_ERROR,
  FETCH_STATS_START,
  FETCH_STATS_DONE,
  FETCH_STATS_ERROR
} = ActivityActions;

const initialState = {
  data: [],
  stats: [],
  fetchingData: false,
  fetchingStats: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ACTIVITY_LIST_START: {
      const { clearList, backgroundUpdate } = payload;

      return {
        ...state,
        ...(clearList ? { data: [], dataHash: null } : {}),
        ...(backgroundUpdate ? {} : { fetchingData: true })
      };
    }

    case FETCH_ACTIVITY_LIST_DONE: {
      const { dataHash, data, backgroundUpdate } = payload;

      return {
        ...state,
        ...(!dataHash || dataHash !== state.dataHash ? {
          data,
          dataHash: dataHash || null
        } : {}),
        ...(backgroundUpdate ? {} : { fetchingData: false })
      };
    }

    case FETCH_ACTIVITY_LIST_ERROR:
      return { ...state, fetchingData: false };

    case FETCH_STATS_START: {
      const { clearList, backgroundUpdate } = payload;

      return {
        ...state,
        ...(clearList ? { stats: [] } : {}),
        ...(backgroundUpdate ? {} : { fetchingStats: true })
      };
    }

    case FETCH_STATS_DONE:
      return { ...state, stats: payload.data, fetchingStats: false };

    case FETCH_STATS_ERROR:
      return { ...state, fetchingStats: false };

    default:
      return state;
  }
};
