import ModalNames from "const/ModalNames";

export default class UiActions {
  static TOGGLE_PRELOADER = "ui/TOGGLE_PRELOADER";

  static TOGGLE_PRELOADER_OVERLAY = "ui/TOGGLE_PRELOADER_OVERLAY";

  static TOGGLE_MODAL_CONTENT = "ui/TOGGLE_MODAL_CONTENT";

  static SHOW_MODAL = "ui/SHOW_MODAL";

  static HIDE_MODAL = "ui/HIDE_MODAL";

  static SHOW_MODAL_IMAGES = "ui/SHOW_MODAL_IMAGES";

  static HIDE_MODAL_IMAGES = "ui/HIDE_MODAL_IMAGES";

  static SHOW_CUSTOM_MODAL = "ui/SHOW_CUSTOM_MODAL";

  static HIDE_CUSTOM_MODAL = "ui/HIDE_CUSTOM_MODAL";

  static togglePreloader(state, preloaderText = null) {
    return { type: UiActions.TOGGLE_PRELOADER, payload: { state, preloaderText } };
  }

  static togglePreloaderOverlay(state) {
    return { type: UiActions.TOGGLE_PRELOADER_OVERLAY, payload: { state } };
  }

  static toggleModalContent(state) {
    return { type: UiActions.TOGGLE_MODAL_CONTENT, payload: { state } };
  }

  static showModal(
    text,
    headerText,
    confirm = false,
    size = "sm",
    okButtonText = null,
    cancelButtonText = null,
    cancelValue = false
  ) {
    return async(dispatch) => {
      const result = await new Promise((resolve) => {
        dispatch({
          type: UiActions.SHOW_MODAL,
          payload: { headerText, text, confirm, size, okButtonText, cancelButtonText, cancelValue },
          meta: { modalResolver: resolve }
        });
      });

      return result;
    };
  }

  static hideModal(modalResult = false) {
    return { type: UiActions.HIDE_MODAL, payload: { modalResult } };
  }

  static showModalImages(sources) {
    return { type: UiActions.SHOW_MODAL_IMAGES, payload: { sources } };
  }

  static hideModalImages() {
    return { type: UiActions.HIDE_MODAL_IMAGES };
  }

  static showCustomModal(modalName, payload) {
    return async(dispatch) => {
      const result = await new Promise((resolve) => {
        dispatch({
          type: UiActions.SHOW_CUSTOM_MODAL,
          payload: { modalName, ...payload },
          meta: { modalResolver: resolve }
        });
      });

      return result;
    };
  }

  static hideCustomModal(modalResult = false) {
    return { type: UiActions.HIDE_CUSTOM_MODAL, payload: { modalResult } };
  }

  static showLinkContactWindow(payload) {
    return UiActions.showCustomModal(ModalNames.LINK_CONTACT_WINDOW, payload);
  }

  static showSelectCategoryWindow(payload) {
    return UiActions.showCustomModal(ModalNames.SELECT_CATEGORY_WINDOW, payload);
  }

  static showSelectClassWindow(payload) {
    return UiActions.showCustomModal(ModalNames.SELECT_CLASS_WINDOW, payload);
  }

  static showSelectLocationWindow(payload) {
    return UiActions.showCustomModal(ModalNames.SELECT_LOCATION_WINDOW, payload);
  }

  static showSelectProjectWindow(payload) {
    return UiActions.showCustomModal(ModalNames.SELECT_PROJECT_WINDOW, payload);
  }

  static showSelectTaxRateWindow(payload) {
    return UiActions.showCustomModal(ModalNames.SELECT_TAX_RATE_WINDOW, payload);
  }

  static showAddTagsWindow(payload) {
    return UiActions.showCustomModal(ModalNames.ADD_TAGS_WINDOW, payload);
  }

  static showAddReasonWindow(payload) {
    return UiActions.showCustomModal(ModalNames.ADD_REASON_WINDOW, payload);
  }

  static showSelectIntegrationBusinessesWindow(payload) {
    return UiActions.showCustomModal(ModalNames.SELECT_BUSINESSES_WINDOW, payload);
  }

  static showAddBusinessWindow(payload) {
    return UiActions.showCustomModal(ModalNames.ADD_BUSINESS_WINDOW, payload);
  }

  static showAddQuickBooksDesktopWindow(payload) {
    return UiActions.showCustomModal(ModalNames.ADD_QUICK_BOOKS_DESKTOP_WINDOW, payload);
  }

  static showEditBusinessSettingsWindow(payload) {
    return UiActions.showCustomModal(ModalNames.EDIT_BUSINESS_SETTINGS_WINDOW, payload);
  }

  static showAskClientWindow(payload) {
    return UiActions.showCustomModal(ModalNames.ASK_CLIENT_WINDOW, payload);
  }

  static showUserDetailsWindow(payload) {
    return UiActions.showCustomModal(ModalNames.USER_DETAILS_WINDOW, payload);
  }

  static showPairTransactionsWindow(payload) {
    return UiActions.showCustomModal(ModalNames.PAIR_TRANSACTIONS_WINDOW, payload);
  }

  static showSetupBackupWindow(payload) {
    return UiActions.showCustomModal(ModalNames.SETUP_BACKUP_WINDOW, payload);
  }
}
