import Css from "./style.module.scss";

import { Button } from "shards-react";
import { FiAlertTriangle } from "react-icons/fi";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { checkAuthState, getAuthErrorDescription } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData, getSelectedBusinessId, getUserBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuthHandler from "nlib/common/AuthHandler";
import BusinessesActions from "actions/BusinessesActions";
import Header from "lib/Header";
import Navbar from "lib/Navbar";
import NoBusinesses from "./lib/NoBusinesses";
import OrganizationsActions from "actions/OrganizationsActions";
import Preloader from "nlib/ui/Preloader";
import React, { Suspense, lazy, useCallback } from "react";
import RedirectToDefaultRoute from "lib/RedirectToDefaultRoute";
import StripeActions from "actions/StripeActions";
import TasksSidePanel from "nlib/common/TasksSidePanel";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const ROUTES = [
  [lazy(() => import("lib/pages/AccountsPage")), UiRoutes.ACCOUNTS],
  [lazy(() => import("lib/pages/TransactionsPage")), UiRoutes.TRANSACTIONS],
  [lazy(() => import("lib/pages/DocumentsPage")), UiRoutes.DOCUMENTS],
  [lazy(() => import("lib/pages/ReportsPage")), UiRoutes.REPORTS],
  [lazy(() => import("lib/pages/VaultPage")), UiRoutes.ARCHIVE],
  [lazy(() => import("lib/pages/ContactsPage")), UiRoutes.CONTACTS],
  [lazy(() => import("lib/pages/DashboardPage")), UiRoutes.DASHBOARD],
  [lazy(() => import("lib/pages/ActivityPage")), UiRoutes.ACTIVITY],
  [lazy(() => import("lib/pages/TasksPage")), UiRoutes.TASKS]
];

const SummaryPage = lazy(() => import("lib/pages/SummaryPage"));

const LoginRedirect = () => {
  const { search, pathname } = useLocation();

  const [envVars] = useEnvVars();

  const userAuthError = !!useSelector(getAuthErrorDescription);

  const query = pathname === UiRoutes.MAIN || userAuthError
    ? (userAuthError ? Utils.objectToQueryString(envVars) : null)
    : Utils.objectToQueryString({ returnTo: btoa(pathname + search) });

  const redirectUrl = `${UiRoutes.LOGIN}${query ? `?${query}` : ""}`;

  return <Redirect to={redirectUrl} />;
};

const Routes = () => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const businessesData = useSelector(getUserBusinessesData);

  const activeOrganization = useSelector(getActiveOrganization);

  const userAuthState = useSelector(checkAuthState);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const onAddBusiness = useCallback(async(result) => {
    const manualIntegrationFlow = false;

    let business;

    if (result) {
      const { name, regId, type, vatPayer } = result;

      dispatch(UiActions.togglePreloader(true));
      business = await dispatch(BusinessesActions.addNewBusiness(name, regId, type, vatPayer));

      if (business) {
        await dispatch(OrganizationsActions.fetchOrganizationsList());
        window.location.href = `/${business.id}`;
      }
      dispatch(UiActions.togglePreloader(false));
    }
    if (!manualIntegrationFlow && !businessesData.length && !business) {
      await dispatch(UiActions.showModal(messages.addFirstBusiness, uiTexts.warning));
    }
  }, [businessesData.length, messages, uiTexts, dispatch]);

  const checkBusinessIdValid = useCallback((businessId) => {
    return businessId && businessesData.some(({ id }) => id === businessId);
  }, [businessesData]);

  const handleAddPaymentMethodClick = useCallback(async() => {
    const sessionUrl = await dispatch(StripeActions.createSetupCheckoutSession());

    if (sessionUrl) {
      window.location.href = sessionUrl;
    }
  }, [dispatch]);

  const handleAddNewBusiness = useCallback(async() => {
    const result = await dispatch(UiActions.showAddBusinessWindow());

    if (result) onAddBusiness(result);
  }, [onAddBusiness, dispatch]);

  return (
    <Switch>
      <Route path={UiRoutes.LOGIN}>{() => userAuthState ? <RedirectToDefaultRoute /> : <AuthHandler />}</Route>
      <Route path="/:businessId?/:currentRoute?">
        {({ match: { params: { businessId } } }) => {
          if (!userAuthState) {
            return <LoginRedirect />;
          }

          if (businessId && !checkBusinessIdValid(businessId)) {
            return <RedirectToDefaultRoute />;
          }

          if (!businessesData.length) {
            return (
              <>
                <Header />
                <NoBusinesses onAddBusiness={onAddBusiness} />
              </>
            );
          }

          if (!businessId) {
            return (
              <>
                <Header onAddNewBusiness={handleAddNewBusiness} />
                <Suspense fallback={<Preloader fixed />}>
                  <SummaryPage onAddNewBusiness={handleAddNewBusiness} />
                </Suspense>
              </>
            );
          }

          if (businessId !== selectedBusinessId) return null;

          const { extraData = {} } = selectedBusinessData;

          const { lastGptAiCategorizedAt } = extraData;

          return (
            <>
              {!!(activeOrganization.appsumoValue && lastGptAiCategorizedAt) && (
                <div className={Css.appsumoInfo}>
                  <FiAlertTriangle />
                  <span>{messages.appsumoLimitedOfferMessage}</span>
                  <Button size="sm" theme="black" onClick={handleAddPaymentMethodClick}>{uiTexts.addPaymentMethod}</Button>
                </div>
              )}
              <Header onAddNewBusiness={handleAddNewBusiness} />
              <Navbar />
              <div className={Css.wrapper}>
                <div id="scroll-container" className={Css.scrollContainer}>
                  <Suspense fallback={<Preloader fixed />}>
                    <Switch>
                      {ROUTES.map(([PageComponent, route]) => (
                        <Route
                          key={route}
                          path={`/${selectedBusinessId}${route}/:section?`}
                          component={PageComponent} />
                      ))}
                      <Route><RedirectToDefaultRoute /></Route>
                    </Switch>
                  </Suspense>
                </div>
                <TasksSidePanel />
              </div>
            </>
          );
        }}
      </Route>
    </Switch>
  );
};

export default React.memo(Routes);
