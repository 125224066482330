import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import Button from "nlib/ui/Button";
import CategoryOrItemsSelect from "./lib/CategoryOrItemsSelect";
import React, { useCallback, useState } from "react";
import SelectClassInput from "nlib/common/SelectClassInput";
import SelectItemInput from "nlib/common/SelectItemInput";
import SelectProjectInput from "nlib/common/SelectProjectInput";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";
import useDocumentContext from "hooks/useDocumentContext";

const BulkActions = (props) => {
  const {
    count,
    paymentType,
    enableCategories,
    enableItems,
    enableClasses,
    enableProjects,
    enableTaxRates,
    onDelete,
    onChange
  } = props;

  const { documentState: { type } } = useDocumentContext();

  const [state, setState] = useState({});

  const handleClassChange = useCallback((val) => {
    setState((prev) => ({ ...prev, class: val }));
  }, []);

  const handleProjectChange = useCallback((val) => {
    setState((prev) => ({ ...prev, project: val }));
  }, []);

  const handleCategoryOrItemChange = useCallback((name, value) => {
    setState(({ category, item, ...rest }) => {
      return { ...rest, [name]: value };
    });
  }, []);

  const handleItemChange = useCallback((value) => {
    setState((prev) => ({ ...prev, item: value }));
  }, []);

  const handleTaxRateChange = useCallback((value) => {
    setState((prev) => ({ ...prev, taxRate: value }));
  }, []);

  const handleSaveClick = useCallback(() => {
    onChange({
      ...(state.category?.code ? { category: state.category } : null),
      ...(state.item?.id ? { item: state.item } : null),
      ...(state.project?.id ? { project: state.project } : null),
      ...(state.class?.id ? { class: state.class } : null),
      ...(state.taxRate?.id ? { taxRate: state.taxRate } : null)
    });
  }, [onChange, state]);

  const handleCloseClick = useCallback(() => {
    onChange();
  }, [onChange]);

  return (
    <div className={Css.bulkActions}>
      {enableCategories && (
        <div className={Css.control}>
          <CategoryOrItemsSelect
            portal validate
            type={type}
            item={state.item}
            category={state.category}
            onChange={handleCategoryOrItemChange} />
        </div>
      )}
      {enableItems && (
        <div className={Css.control}>
          <SelectItemInput
            portal validate
            paymentType={paymentType}
            value={state.item}
            onChange={handleItemChange} />
        </div>
      )}
      {enableClasses && (
        <div className={Css.control}>
          <SelectClassInput
            portal validate
            value={state.class}
            dropDownClassName={Css.dropDownClassName}
            onChange={handleClassChange} />
        </div>
      )}
      {enableProjects && (
        <div className={Css.control}>
          <SelectProjectInput
            portal validate
            value={state.project}
            dropDownClassName={Css.dropDownClassName}
            onChange={handleProjectChange} />
        </div>
      )}
      {enableTaxRates && (
        <div className={Css.control}>
          <SelectTaxRateInput
            portal validate
            value={state.taxRate}
            dropDownClassName={Css.dropDownClassName}
            onChange={handleTaxRateChange} />
        </div>
      )}
      <Button
        primary
        disabled={!state.category?.code && !state.item?.id && !state.project?.id && !state.class?.id}
        className={Css.button}
        onClick={handleSaveClick}>
        <Icons.Check />
      </Button>
      <Button
        danger
        disabled={!(count > 1)}
        className={Css.button}
        onClick={onDelete}>
        <Icons.Trash />
      </Button>
      <Button
        outline
        className={Css.button}
        onClick={handleCloseClick}>
        <Icons.X />
      </Button>
    </div>
  );
};

export default React.memo(BulkActions);
