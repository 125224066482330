import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { NavLink } from "react-router-dom";
import React from "react";
import classNames from "classnames";

const THEMES = {
  "royal-blue": Css.royalBlue,
  highlight: Css.highlight,
  success: Css.success,
  warning: Css.warning,
  primary: Css.primary,
  danger: Css.danger
};

const Counter = (props) => {
  const {
    to,
    theme,
    loading,
    className,
    description,
    mainText = null,
    secondaryText = null,
    icon: Icon = Icons.Info
  } = props;

  return (
    <div className={classNames(Css.counter, THEMES[theme], loading && Css.loading, className)}>
      <NavLink to={to} className={classNames(CommonCss.card, Css.card)}>
        <div className={Css.icon}><Icon /></div>
        <div className={Css.number}>
          <span>{loading ? "" : mainText}</span>
          <small>{loading ? "" : secondaryText}</small>
        </div>
        <div className={Css.desc}>
          {description}
        </div>
      </NavLink>
    </div>
  );
};

export default React.memo(Counter);
