import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkActivityStatsFetching, getActivityStatsData } from "selectors/activity";
import { checkSelectedBusinessHasBusinessUsers } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ActivityActions from "actions/ActivityActions";
import Constants from "const/Constants";
import MonthStats from "./lib/MonthStats";
import NoDataContent from "nlib/common/NoDataContent";
import Preloader from "nlib/common/Preloader";
import React, { useLayoutEffect } from "react";
import Utils from "utils/Utils";

const StatsTab = () => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const activityStatsData = useSelector(getActivityStatsData);

  const activityFetching = useSelector(checkActivityStatsFetching);

  const hasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  useLayoutEffect(() => {
    dispatch(ActivityActions.fetchStats(true));

    return Utils.setInterval(() => dispatch(ActivityActions.fetchStats(false, true)), Constants.DATA_LIST_UPDATE_INTERVAL);
  }, [dispatch]);

  return (
    <div className={Css.activityStats}>
      <div className={Css.headers} disabled={!activityStatsData.length}>
        <div className={Css.header}>
          <div>{uiTexts.period}</div>
        </div>
        <div className={Css.header}>
          <div title={uiTexts.categorizedAndApproved}>{uiTexts.categorizedAndApproved}</div>
        </div>
        <div className={Css.header}>
          <div title={uiTexts.byRoboticAi}>{uiTexts.byRoboticAi}</div>
        </div>
        <div className={Css.header}>
          <div title={uiTexts.byAccountants}>{uiTexts.byAccountants}</div>
        </div>
        {hasBusinessUsers && (
          <div className={Css.header}>
            <div title={uiTexts.byClients}>{uiTexts.byClients}</div>
          </div>
        )}
      </div>
      {activityStatsData.length
        ? activityStatsData.map((monthData, index) => {
          return <MonthStats key={monthData.month} monthData={monthData} initialOpened={!index} />;
        })
        : (
          <div className={Css.emptyState}>
            {activityFetching ? <Preloader /> : (
              <NoDataContent
                icon={Icons.ChartPieSlice}
                title={uiTexts.noData} />
            )}
          </div>
        )}
    </div>
  );
};

export default React.memo(StatsTab);
