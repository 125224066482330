import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { useSelector } from "react-redux";
import CommentsBlock from "nlib/common/CommentsBlock";
import DataConstants from "const/DataConstants";
import DropDown from "nlib/ui/DropDown";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import React, { useCallback, useState } from "react";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const {
  COMMENT_TARGET_TYPES: { TRANSACTIONS, AUDIT },
  STATUSES: { EXPORTED }
} = DataConstants;

const Comments = (props) => {
  const {
    audit,
    status,
    disabled,
    transactionId,
    comments,
    comments: {
      all: allCommentsCount = 0,
      unread: unreadCommentsCount = 0
    } = {},
    onAskClient
  } = props;

  const [{ editItem }] = useEnvVars();

  const [searchTransactionId, sectionName] = (editItem || "").split(".");

  const businessUser = useSelector(checkIsBusinessUser);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const [dropdownOpened, setDropdownOpened] = useState(searchTransactionId === transactionId && sectionName === "comments");

  const disableAskClient = businessUser || (!quickBooksBusiness && status === EXPORTED);

  const handleCloseClick = useCallback(() => {
    setDropdownOpened(false);
  }, []);

  const handleShowMoreClick = useCallback(() => {
    setDropdownOpened(true);
  }, []);

  const handleAskClient = useCallback(() => {
    onAskClient();
  }, [onAskClient]);

  return (
    <div className={Css.comments}>
      <DropDown opened={dropdownOpened} setOpened={setDropdownOpened}>
        <div
          disabled={disabled}
          className={Css.button}
          onClick={handleShowMoreClick}>
          <Icons.ChatText weight={(dropdownOpened) ? "fill" : "regular"} />
          <Badge
            title={allCommentsCount}
            attention={!!unreadCommentsCount}
            className={Css.badge}
            value={allCommentsCount} />
        </div>
        <DropDownContent portal className={classNames(Css.dropdownContent, businessUser && Css.businessUser)}>
          <CommentsBlock
            popup
            className={Css.commentsBlock}
            compact={businessUser}
            commentTarget={audit ? AUDIT : TRANSACTIONS}
            commentTargetId={transactionId}
            comments={comments}
            onClose={handleCloseClick}
            onAskClient={disableAskClient ? null : handleAskClient} />
        </DropDownContent>
      </DropDown>
    </div>
  );
};

export default React.memo(Comments);
