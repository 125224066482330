import Css from "./style.module.scss";

import { Preloader } from "nlib/ui";
import { getCommentsData, getUnreadCommentsData } from "selectors/comments";
import { getEventsData } from "selectors/events";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { usePreviousValue } from "hooks";
import ActivityContainer from "./lib/ActivityContainer";
import AddCommentField from "nlib/common/AddCommentField";
import Button from "nlib/ui/Button";
import Comment from "./lib/Comment";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import Event from "./lib/Event";
import EventsActions from "actions/EventsActions";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const { COMMENT_TARGET_TYPES: { TRANSACTIONS, DOCUMENTS } } = DataConstants;

const MAX_ITEMS_COUNT = 5;

let messageDraft = "";

const ITEMS_WITH_EVENTS = [TRANSACTIONS, DOCUMENTS];

const Activity = (props) => {
  const [localDataLoaded, setLocalDataLoaded] = useState(props.dataLoadedInitial);

  const {
    mobile,
    type,
    itemId,
    disabled,
    scrollTo,
    className,
    dataLoaded = localDataLoaded,
    setDataLoaded = setLocalDataLoaded,
    showAllInitial,
    alignUserListRight,
    commentsCount = {}
  } = props;

  const { all: allCommentsCount = 0 } = commentsCount;

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const commentsData = useSelector(getCommentsData);

  const { [type]: eventsData = [] } = useSelector(getEventsData);

  const unreadCommentsData = useSelector(getUnreadCommentsData);

  const unreadCommentsCount = unreadCommentsData
    .filter(({ targetId, targetType }) => targetId === itemId && type === targetType).length;

  const [showAll, setShowAll] = useState(showAllInitial);

  const [comment, setComment] = useState(messageDraft);

  const containerRef = useRef();

  const inputRef = useRef();

  const previousAllCommentsCount = usePreviousValue(allCommentsCount);

  const fetchData = useCallback(async() => {
    const promises = [dispatch(CommentsActions.fetchComments(type, itemId, true))];

    if (ITEMS_WITH_EVENTS.includes(type)) {
      promises.push(dispatch(EventsActions.fetchEventsByRoute(`/${type}`, itemId)));
    }

    await Promise.all(promises);
    setDataLoaded(true);
  }, [dispatch, itemId, setDataLoaded, type]);

  const handleShowAllClick = useCallback(() => {
    setShowAll(true);
  }, []);

  const handleUserMention = useCallback((mention) => {
    setComment((prev) => {
      const text = prev.trim();

      messageDraft = `${text ? `${text} ` : ""}${mention} `;

      return messageDraft;
    });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleCommentChange = useCallback((value) => {
    messageDraft = value;
    setComment(value);
  }, []);

  useEffect(() => {
    if (!scrollTo || !containerRef.current) return;
    containerRef.current.scrollIntoView();
  }, [scrollTo, commentsData.length]);

  useEffect(() => {
    if (props.dataLoadedInitial) return;
    fetchData();
  }, [props.dataLoadedInitial, fetchData]);

  useEffect(() => {
    if (allCommentsCount < previousAllCommentsCount) {
      dispatch(CommentsActions.fetchComments(type, itemId));
    }
  }, [allCommentsCount, dispatch, itemId, previousAllCommentsCount, type]);

  useEffect(() => {
    if (unreadCommentsCount && dataLoaded) {
      dispatch(CommentsActions.fetchComments(type, itemId));
    }
  }, [dispatch, dataLoaded, itemId, type, unreadCommentsCount]);

  const items = dataLoaded ? [...commentsData, ...eventsData] : [];

  return (
    <div className={classNames(Css.activity, className)} ref={containerRef}>
      <ActivityContainer
        className={Css.addComment}
        infoContent={(
          <AddCommentField
            portal={!mobile}
            alignUserListRight={alignUserListRight}
            value={comment}
            disabled={disabled}
            inputRef={inputRef}
            className={Css.addCommentField}
            onChange={handleCommentChange} />
        )} />
      {items.length
        ? (
          <>
            {Utils.arraySort(items, "createdAt")
              .slice(0, showAll ? items.length : MAX_ITEMS_COUNT)
              .map((activity) => {
                if (activity.rev) {
                  const index = eventsData.indexOf(activity);

                  const prevEventData = eventsData[index - 1];

                  const { rev, action, user, document, updates, createdAt } = activity;

                  const oldest = index === 0;

                  return (
                    <Event
                      key={rev}
                      user={user}
                      createdAt={createdAt}
                      action={action ? action : "systemUpdate"}
                      updates={oldest && action === "added" ? document : updates}
                      prevRevDocument={prevEventData ? prevEventData.document : null}
                      currentRevDocument={eventsData[index].document} />
                  );
                }

                return (
                  <Comment
                    key={activity.id}
                    activity={activity}
                    onUserMention={handleUserMention} />
                );
              })}
            {(items.length > MAX_ITEMS_COUNT) && !showAll && (
              <Button outline className={Css.viewAll} onClick={handleShowAllClick}>
                {`${uiTexts.showMore} (${items.length - MAX_ITEMS_COUNT})`}
              </Button>
            )}
          </>
        )
        : (!dataLoaded && <Preloader />)}
    </div>
  );
};

export default React.memo(Activity);
