import Css from "./style.module.scss";

import coloredLogo from "assets/coloredLogo.svg";

import * as Icons from "@phosphor-icons/react";
import { Avatar, PreloaderDotted } from "nlib/ui";
import { getActiveOrganization, getProjectName } from "selectors/organizations";
import { getCurrentQuickBooksRealmId, getCurrentXeroOrganizationId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useMainApi } from "hooks";
import { useSelector } from "react-redux";
import ActivityEvents from "const/ActivityEvents";
import Constants from "const/Constants";
import Countries from "const/Countries";
import ExpandedListItem from "./lib/ExpandedListItem";
import React, { useCallback, useMemo, useState } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useActivityEventDescription from "hooks/useActivityEventDescription";

const { DATETIME_FORMATS: { DATETIME_TEXT } } = Constants;

const {
  TYPES: {
    NEW_BUSINESS_ADDED,
    RPA_STARTED,
    RPA_FINISHED,
    RPA_FAILED,
    GPT_AI_FINE_TUNE_STARTED,
    GPT_AI_FINE_TUNE_FINISHED,
    GPT_AI_FINE_TUNE_FAILED,
    TRANSACTIONS_NOT_CATEGORIZED,
    TRANSACTIONS_AUTO_CATEGORIZED,
    TRANSACTIONS_AUTO_PAIRED,
    TRANSACTIONS_CATEGORIZED,
    TRANSACTIONS_AUTO_APPROVED,
    TRANSACTIONS_APPROVED,
    TRANSACTIONS_EDITED,
    TRANSACTIONS_AUTO_ASK_CLIENT,
    TRANSACTIONS_ASK_CLIENT,
    TRANSACTIONS_REPLIED_BY_CLIENT,
    USER_INVITED,
    USER_INVITE_FOLLOW_UP,
    USER_REVOKED,
    DOCUMENTS_UPLOADED,
    DOCUMENTS_APPROVED,
    DOCUMENTS_DELETED,
    DOCUMENT_RECOGNITION_FAILED,
    TRANSACTIONS_ASK_CLIENT_FOLLOW_UP,
    TASK_ADDED,
    TASK_REMOVED,
    TASK_STATUS_CHANGED,
    TASK_MARKED_AS_COMPLETED,
    TASK_TARGET_USER_CHANGED
  },
  EDITABLE_ACTIVITY_PROPERTIES
} = ActivityEvents;

const ICONS_BY_TYPE = {
  [TASK_ADDED]: Icons.CheckSquareOffset,
  [TASK_REMOVED]: Icons.Trash,
  [TASK_STATUS_CHANGED]: Icons.CheckSquare,
  [TASK_MARKED_AS_COMPLETED]: Icons.CheckSquare,
  [TASK_TARGET_USER_CHANGED]: Icons.User,
  [USER_INVITE_FOLLOW_UP]: Icons.Envelope,
  [TRANSACTIONS_ASK_CLIENT_FOLLOW_UP]: Icons.Envelope,
  [NEW_BUSINESS_ADDED]: Icons.Briefcase,
  [TRANSACTIONS_CATEGORIZED]: Icons.Tag,
  [TRANSACTIONS_APPROVED]: Icons.Check,
  [TRANSACTIONS_EDITED]: Icons.PencilSimple,
  [TRANSACTIONS_ASK_CLIENT]: Icons.Question,
  [TRANSACTIONS_REPLIED_BY_CLIENT]: Icons.ChatText,
  [RPA_STARTED]: Icons.Cpu,
  [RPA_FINISHED]: Icons.Cpu,
  [RPA_FAILED]: Icons.Cpu,
  [GPT_AI_FINE_TUNE_STARTED]: Icons.Cpu,
  [GPT_AI_FINE_TUNE_FINISHED]: Icons.Cpu,
  [GPT_AI_FINE_TUNE_FAILED]: Icons.Cpu,
  [TRANSACTIONS_NOT_CATEGORIZED]: Icons.Cpu,
  [TRANSACTIONS_AUTO_CATEGORIZED]: Icons.Cpu,
  [TRANSACTIONS_AUTO_PAIRED]: Icons.Cpu,
  [TRANSACTIONS_AUTO_APPROVED]: Icons.Cpu,
  [TRANSACTIONS_AUTO_ASK_CLIENT]: Icons.Cpu,
  [USER_INVITED]: Icons.UserPlus,
  [USER_REVOKED]: Icons.UserMinus,
  [DOCUMENTS_UPLOADED]: Icons.CloudArrowUp,
  [DOCUMENTS_APPROVED]: Icons.Check,
  [DOCUMENTS_DELETED]: Icons.Trash,
  [DOCUMENT_RECOGNITION_FAILED]: Icons.CloudSlash
};

const TYPE_COLOR = {
  [RPA_FAILED]: Css.failed,
  [GPT_AI_FINE_TUNE_FAILED]: Css.failed
};

const PREV_VALUE_PROP_NAMES = Object.fromEntries(
  EDITABLE_ACTIVITY_PROPERTIES.map(({ propName, prevPropName }) => [propName, prevPropName])
);

const ActivityItem = ({ item }) => {
  const {
    id: activityId,
    type,
    system,
    info,
    timestamp,
    expandedList = [],
    expandedListSize
  } = item;

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const projectName = useSelector(getProjectName);

  const [expandedListShown, setExpandedListShown] = useState(false);

  const [{ expandedList: fullExpandedList }, fetching] = useMainApi({
    initialData: { expandedList: [] },
    fetchCondition: expandedListShown,
    parameters: [`${UiRoutes.ACTIVITY}/${activityId}`],
    dependencies: [expandedListShown]
  });

  const itemsList = (fullExpandedList.length ? fullExpandedList : expandedList);

  const columnNames = useMemo(() => {
    const usCountry = activeOrganization.countryCode === Countries.US;

    const hasReason = type === TRANSACTIONS_REPLIED_BY_CLIENT
      && itemsList.some((transaction) => !transaction.category && !transaction.prevCategory
      && !transaction.item && !transaction.prevItem && (transaction.reason || transaction.prevReason));

    const hasItem = itemsList.some((transaction) => transaction.item || transaction.prevItem);

    return [
      ["payee"],
      [
        hasReason && "reason",
        ((!hasReason && !hasItem) || itemsList.some((transaction) => transaction.category || transaction.prevCategory))
          && "category",
        itemsList.some((transaction) => transaction.item || transaction.prevItem) && "item"
      ].filter(Boolean),
      ["class"],
      quickBooksBusiness && ["location"],
      !xeroBusiness && ["project"],
      !usCountry && ["taxRate"]
    ].filter(Boolean);
  }, [
    type,
    itemsList,
    xeroBusiness,
    activeOrganization,
    quickBooksBusiness
  ]);

  const shownColumnNames = useMemo(() => {
    return columnNames.filter((keys) => {
      return itemsList.some((transaction) => keys.some((key) => transaction[key] || transaction[PREV_VALUE_PROP_NAMES[key]]));
    });
  }, [columnNames, itemsList]);

  const descriptionColumnShown = useMemo(() => {
    return itemsList.some((transaction) => transaction.description);
  }, [itemsList]);

  const userName = useMemo(() => {
    if (system) return projectName;

    const { user, sender, recipient, businessName } = info;

    return (user && (user.userFullName || user.email))
      || (sender && (sender.userFullName || sender.email))
      || (recipient && (recipient.userFullName || recipient.email))
      || businessName
      || uiTexts.unknown;
  }, [info, projectName, system, uiTexts]);

  const description = useActivityEventDescription(item);

  const timeDifference = moment(timestamp).diff(moment());

  const { logoUrl = coloredLogo } = activeOrganization.whiteLabel || {};

  const Icon = ICONS_BY_TYPE[type] || Icons.Pulse;

  const handleShowMoreClick = useCallback(() => {
    setExpandedListShown(true);
  }, []);

  return (
    <div className={classNames(Css.listItem, system && Css.system)}>
      <div className={classNames(Css.type, TYPE_COLOR[type])}><Icon /></div>
      <div className={Css.card}>
        <div className={Css.title}>
          <Avatar
            className={Css.avatar}
            backgroundColor={system ? Constants.COLORS.WHITE : undefined}
            src={system ? logoUrl : undefined}
            title={userName} />
          <div className={Css.titleContent}>
            <div className={Css.label}>
              {description}
            </div>
            <div className={Css.date}>
              {Math.abs(timeDifference) > moment.duration(1, "days").asMilliseconds()
                ? moment(timestamp).format(DATETIME_TEXT)
                : moment.duration(timeDifference).humanize(true)}
            </div>
          </div>
        </div>
        {!!itemsList?.length && (
          <div className={Css.list}>
            {type !== ActivityEvents.TYPES.DOCUMENT_RECOGNITION_FAILED && (
              <ExpandedListItem
                header
                type={type}
                columnNames={columnNames}
                possibleColumnCount={columnNames.length}
                shownColumnNames={shownColumnNames}
                descriptionColumnShown={descriptionColumnShown} />
            )}
            {itemsList
              .map((listItem, index) => (
                <ExpandedListItem
                  key={String(index + 1)}
                  type={type}
                  item={listItem}
                  possibleColumnCount={columnNames.length}
                  shownColumnNames={shownColumnNames}
                  descriptionColumnShown={descriptionColumnShown} />
              ))}
            {!!(expandedListSize > itemsList.length) && (
              <div className={Css.footer}>
                <div>
                  {expandedListShown && fetching
                    ? <PreloaderDotted className={Css.preloader} />
                    : (
                      <div disabled={expandedListShown} className={Css.more} onClick={handleShowMoreClick}>
                        {Utils.replaceTextVars(
                          uiTexts.andCountMore.toLowerCase(),
                          { count: expandedListSize - itemsList.length }
                        )}
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ActivityItem);
