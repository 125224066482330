import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge, Button } from "nlib/ui";
import { checkIsBusinessUser, getUserData } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useTaskContent from "hooks/useTaskContent";

const { TASK_STATUSES: { COMPLETED, TO_DO }, TASK_TYPES } = DataConstants;

const TITLE_MAX_LENGTH = 56;

const STATUS_CLASS_NAMES = {
  [COMPLETED]: Css.completed
};

const TaskListItem = ({ className, taskData }) => {
  const [, setEnvVars] = useEnvVars();

  const {
    id: taskId,
    status,
    createdBy,
    comments,
    type,
    autoTask = false,
    attachmentsCount
  } = taskData;

  const {
    startDateContent,
    dueDateContent,
    dueDateStatus,
    createdByContent,
    createdAtContent,
    taskForContent
  } = useTaskContent(taskData);

  const dispatch = useDispatch();

  const { id: userId, role: userRole } = useSelector(getUserData);

  const { uiTexts, messages } = useSelector(getTextsData);

  const showCommonModal = useShowCommonModal();

  const businessUser = useSelector(checkIsBusinessUser);

  const completed = status === COMPLETED;

  const title = (taskData.title || taskData.preview || "");

  const createdByAtContent = useMemo(() => {
    return [createdBy ? createdByContent : uiTexts.system, createdAtContent].filter(Boolean).join(", ");
  }, [createdBy, createdByContent, createdAtContent, uiTexts]);

  const canBeManaged = useMemo(() => {
    return !autoTask && (userRole === UserRoles.ACCOUNTANT_ADMIN.roleId || (createdBy && createdBy.id === userId));
  }, [autoTask, userRole, createdBy, userId]);

  const handleRemoveMouseDown = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleClick = useCallback(() => {
    setEnvVars({ editItem: null, editTask: taskId });
  }, [setEnvVars, taskId]);

  const handleRemoveClick = useCallback(async(event) => {
    event.stopPropagation();

    let result = await showCommonModal({ confirm: true, text: messages.taskDeleteConfirm });

    if (!result) return;

    result = await dispatch(TasksActions.deleteTask(taskId));
  }, [taskId, messages, dispatch, showCommonModal]);

  return (
    <div
      className={classNames(Css.taskContent, STATUS_CLASS_NAMES[status], className)}
      onClick={handleClick}>
      <div className={Css.preview} data-value={businessUser ? (status === COMPLETED ? COMPLETED : TO_DO) : status}>
        {`${title.slice(0, TITLE_MAX_LENGTH)}${title?.length > TITLE_MAX_LENGTH ? "..." : ""}`}
      </div>
      <div className={Css.info}>
        <div className={Css.infoItem}>
          <div className={Css.infoItemTitle}>{uiTexts.createdBy}</div>
          <div className={Css.infoItemValue}>
            {createdByAtContent}
          </div>
        </div>

        {!!startDateContent && (
          <div className={Css.infoItem}>
            <div className={Css.infoItemTitle}>
              {type === TASK_TYPES.CUSTOM ? uiTexts.startDate : uiTexts.period}
            </div>
            <div className={Css.infoItemValue}>
              <Badge outline>{startDateContent}</Badge>
            </div>
          </div>
        )}

        {!!dueDateContent && (
          <div className={Css.infoItem}>
            <div className={Css.infoItemTitle}>{uiTexts.deadline}</div>
            <div className={Css.infoItemValue}>
              <Badge theme={!completed && dueDateStatus}>{dueDateContent}</Badge>
            </div>
          </div>
        )}

        <div className={Css.infoItem}>
          <div className={Css.infoItemTitle}>{uiTexts.taskFor}</div>
          <div className={Css.infoItemValue}>
            <Icons.User />
            <span>{taskForContent}</span>
          </div>
        </div>

        <div className={Css.infoItem}>
          <div className={Css.infoItemTitle}>{uiTexts.created}</div>
          <div className={Css.infoItemValue}>
            <span>{createdAtContent}</span>
          </div>
        </div>
      </div>
      <div className={Css.footer}>
        <div className={classNames(Css.item, Css.attachments)}>
          <Icons.FileText />
          <Badge counter>{attachmentsCount}</Badge>
        </div>
        <div className={classNames(Css.item, Css.comments)}>
          <Icons.ChatText />
          <Badge counter theme={comments.unread && "attention"}>{comments.all}</Badge>
        </div>
        <div className={Css.item}>
          {canBeManaged && (
            <Button
              outline
              className={Css.remove}
              onMouseDown={handleRemoveMouseDown}
              onClick={handleRemoveClick}>
              <Icons.Trash />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TaskListItem);
