import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Tab, Tabs } from "nlib/ui";
import { checkSelectedBusinessRpaMode, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FeedTab from "./lib/FeedTab";
import MobileHeader from "./lib/MobileHeader";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import React, { useCallback } from "react";
import StatsTab from "./lib/StatsTab";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";

const TAB_NAMES = {
  FEED: "feed",
  STATS: "stats"
};

const ActivityPage = ({ className }) => {
  const history = useHistory();

  const { uiTexts } = useSelector(getTextsData);

  const { section: currentTab = TAB_NAMES.FEED } = useParams();

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const allowStats = !Utils.checkIsTouchDevice() && rpaMode;

  const handleTabChange = useCallback((nextTab) => {
    history.push(`/${selectedBusinessId}${UiRoutes.ACTIVITY}/${nextTab}`);
  }, [history, selectedBusinessId]);

  return (
    <>
      {Utils.checkIsTouchDevice() && <MobileHeader />}
      <Page className={classNames(Css.activityPage, className)}>
        <PageContent>
          {!Utils.checkIsTouchDevice() && <PageHeader className={Css.pageHeader} />}
          {allowStats && (
            <Tabs className={Css.tabs} current={currentTab} onChange={handleTabChange}>
              <Tab value={TAB_NAMES.FEED}>
                <Icons.List />
                <span>{uiTexts.eventLog}</span>
              </Tab>
              <Tab value={TAB_NAMES.STATS}>
                <Icons.ChartPieSlice />
                <span>{uiTexts.insights}</span>
              </Tab>
            </Tabs>
          )}
          {(currentTab === TAB_NAMES.STATS) && allowStats ? <StatsTab /> : <FeedTab />}
        </PageContent>
      </Page>
    </>
  );
};

export default React.memo(ActivityPage);
