/* eslint-disable no-magic-numbers */
import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Preloader } from "nlib/ui";
import { getAccountsData } from "selectors/accounts";
import { getAuditData } from "selectors/audit";
import { getContactsData } from "selectors/contacts";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getTransactionsData } from "selectors/transactions";
import { useMainApi } from "hooks";
import { useSelector } from "react-redux";
import ConfidenceAppend from "nlib/common/ConfidenceAppend";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import MainApiRoutes from "const/MainApiRoutes";
import NoDataContent from "nlib/common/NoDataContent";
import React, { useCallback, useMemo } from "react";
import RestApi from "api/RestApi";
import SideBar, { SideBarContent, SideBarHeader } from "nlib/common/SideBar";
import TableRow from "./lib/TableRow";
import TransactionAmount from "nlib/common/TransactionAmount";
import Transactions from "utils/Transactions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const { ADVANCED_TRANSACTION_TYPES } = DataConstants;

const ENTITIES = [
  {
    key: "category",
    icon: Icons.SquaresFour
  },
  {
    key: "class",
    icon: Icons.Stack
  },
  {
    key: "location",
    icon: Icons.MapPin
  },
  {
    key: "project",
    icon: Icons.Flag
  }
];

const RpaDetailsPanel = ({ audit = false }) => {
  const [{ transactionId }, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const storedTransactions = useSelector(getTransactionsData);

  const storedAuditTransactions = useSelector(getAuditData);

  const accountsData = useSelector(getAccountsData);

  const contactsData = useSelector(getContactsData);

  const storedTransaction = useMemo(() => {
    return (audit ? storedAuditTransactions : storedTransactions).find(({ id }) => id === transactionId);
  }, [audit, storedAuditTransactions, storedTransactions, transactionId]);

  const [transactionsResponse] = useMainApi({
    method: RestApi.REQUEST_METHODS.POST,
    initialData: { results: [] },
    fetchCondition: transactionId && !storedTransaction,
    parameters: [
      `${MainApiRoutes.BUSINESSES}/${selectedBusinessId}${
        audit
          ? `${MainApiRoutes.AUDIT}${MainApiRoutes.TRANSACTIONS}/${Constants.AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED}`
          : MainApiRoutes.TRANSACTIONS
      }`, null, { text: transactionId }
    ],
    dependencies: [transactionId, storedTransaction]
  });

  const [{ result: rpaDetails }, rpaDetailsFetching] = useMainApi({
    method: RestApi.REQUEST_METHODS.GET,
    initialData: { result: null },
    fetchCondition: transactionId,
    parameters: [
      `${MainApiRoutes.BUSINESSES}/${selectedBusinessId}${MainApiRoutes.ACTIVITY}${MainApiRoutes.RPA_DETAILS}/${transactionId}`
    ],
    dependencies: [transactionId]
  });

  const transactionData = storedTransaction || transactionsResponse?.results?.[0];

  const {
    type,
    amount,
    address,
    currency,
    vendorId,
    accountId,
    timestamp,
    description,
    account = accountsData.find(({ id }) => id === accountId),
    extraData: { gptAiCategory, gptAiDescription, gptAiReason } = {}
  } = transactionData || {};

  const advancedType = Transactions.getTransactionAdvancedType(transactionData || {});

  const addressData = contactsData.find(({ id }) => id === vendorId) || address;

  const { matchingTransactions, confidences = {}, categorizedByFlow } = rpaDetails || {};

  const [
    hardExcludeText,
    noBankDescriptionText,
    inProgressText,
    softExcludePaymentSystemText,
    softExcludePayrollSystemText,
    softExcludeAtmWithdrawalText,
    softExcludeCheckText,
    softExcludeUnclearText,
    softExcludeCustomText,
    categorizedByRuleText,
    categorizedByReasonText,
    categorizedByDescriptionText,
    categorizedByVendorText,
    categorizedByAiText,
    noCategorizationDetailsText
  ] = messages.rpaDetails;

  const handleCloseClick = useCallback(() => {
    setEnvVars({ transactionId: null });
  }, [setEnvVars]);

  const noAiDescriptionText = useMemo(() => {
    const { TRANSFER, PAYMENT, BILL_PAYMENT, SALES_RECEIPT, REFUND_RECEIPT } = ADVANCED_TRANSACTION_TYPES;

    if ([TRANSFER, PAYMENT, BILL_PAYMENT, SALES_RECEIPT, REFUND_RECEIPT].includes(advancedType)) {
      return Utils.replaceTextVars(hardExcludeText, { type: uiTexts[advancedType] });
    }

    if (!description) {
      return noBankDescriptionText;
    }

    if (!gptAiCategory) {
      return inProgressText;
    }

    if (gptAiCategory.startsWith("EXCLUDE_RULE")) {
      switch (gptAiCategory) {
        case "EXCLUDE_RULE_PAYMENT_SYSTEM":
          return softExcludePaymentSystemText;
        case "EXCLUDE_RULE_PAYROLL_SYSTEM":
          return softExcludePayrollSystemText;
        case "EXCLUDE_RULE_ATM_WITHDRAWAL":
          return softExcludeAtmWithdrawalText;
        case "EXCLUDE_RULE_CHECK":
          return softExcludeCheckText;
        case "EXCLUDE_RULE_UNCLEAR":
          return softExcludeUnclearText;
        case "EXCLUDE_RULE_CUSTOM":
          return softExcludeCustomText;
      }
    }

    return null;
  }, [
    description,
    advancedType,
    gptAiCategory,
    uiTexts,
    hardExcludeText,
    noBankDescriptionText,
    inProgressText,
    softExcludePaymentSystemText,
    softExcludePayrollSystemText,
    softExcludeAtmWithdrawalText,
    softExcludeCheckText,
    softExcludeUnclearText,
    softExcludeCustomText
  ]);

  const categorizedByFlowText = useMemo(() => {
    switch (categorizedByFlow) {
      case "rule":
        return categorizedByRuleText;
      case "reason":
        return categorizedByReasonText;
      case "description":
        return categorizedByDescriptionText;
      case "vendor":
        return categorizedByVendorText;
      default:
        return categorizedByAiText;
    }
  }, [
    categorizedByFlow,
    categorizedByRuleText,
    categorizedByReasonText,
    categorizedByDescriptionText,
    categorizedByVendorText,
    categorizedByAiText
  ]);

  if (!transactionData) {
    return (
      <SideBar className={Css.rpaDetailsPanel}>
        <SideBarHeader onCloseClick={handleCloseClick} />
        <SideBarContent>
          <Preloader />
        </SideBarContent>
      </SideBar>
    );
  }

  return (
    <SideBar className={Css.rpaDetailsPanel}>
      <SideBarHeader className={Css.sideBarHeader} onCloseClick={handleCloseClick}>
        <TransactionAmount
          className={Css.amount}
          amount={amount}
          type={type}
          currency={currency} />
      </SideBarHeader>
      <SideBarContent>
        <div className={Css.info}>
          <Icons.CalendarBlank />
          <span>{moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)}</span>
        </div>
        {!!account?.name && (
          <div className={Css.account}>
            <Icons.CreditCard />
            <span>{account?.name}</span>
          </div>
        )}
        {!!address?.name && (
          <div className={Css.payee}>
            <div className={Css.title}>
              <span>{uiTexts.payeeInfo}</span>
            </div>
            <div className={Css.description}>
              <Icons.AddressBook />
              <span>{address?.name}</span>
              {!!addressData?.extraData?.gptAiDescription && (
                <>
                  <span> - </span>
                  <span>{addressData?.extraData?.gptAiDescription}</span>
                </>
              )}
            </div>
          </div>
        )}
        <div className={Css.title}>{uiTexts.bankDescription}</div>
        <div className={classNames(Css.description, !description && Css.muted)} title={description}>
          <Icons.Info />
          <span>{description || `${uiTexts.noBankDescriptionOrMemo} :(`}</span>
        </div>

        <div className={Css.title}>{uiTexts.aiDescription}</div>
        <div className={Css.description}>
          <Icons.Cpu />
          <span>{noAiDescriptionText || gptAiDescription}</span>
        </div>
        {!!gptAiReason && (
          <>
            <div className={Css.title}>{uiTexts.aiReason}</div>
            <div className={Css.description}>
              <Icons.Chat />
              <span>{gptAiReason}</span>
            </div>
          </>
        )}

        {matchingTransactions
          ? (
            <>
              {ENTITIES.some(({ key: entityKey }) => matchingTransactions[entityKey]?.length) && (
                <>
                  <div className={Css.title}>{uiTexts.categorizedByRoboticAi}</div>
                  {!!categorizedByFlow && (
                    <div className={Css.description}>
                      <Icons.SquaresFour />
                      <span>{categorizedByFlowText}</span>
                    </div>
                  )}
                </>
              )}
              {ENTITIES.map(({ key: entityKey, icon: Icon }) => {
                return !!matchingTransactions[entityKey]?.length && (
                  <div key={entityKey} className={Css.entity}>
                    <div className={Css.value}>
                      <Icon data-tooltip={uiTexts[entityKey]} />
                      <div className={Css.text} title={rpaDetails[entityKey]}>{rpaDetails[entityKey]}</div>
                      <ConfidenceAppend
                        className={Css.confidence}
                        confidence={confidences[entityKey] || transactionData[entityKey]?.confidence} />
                    </div>
                    <div className={Css.title}>Similar transactions</div>
                    <div className={Css.table}>
                      <TableRow entityKey={entityKey} header />
                      {matchingTransactions[entityKey].map((item, index) => (
                        <TableRow key={item.id || index} entityKey={entityKey} item={item} currency={currency} />
                      ))}
                    </div>
                  </div>
                );
              })}
            </>
          )
          : (
            rpaDetailsFetching ? <Preloader /> : (
              <NoDataContent className={Css.noData} icon={Icons.SquaresFour}>
                <div className={Css.emptyText}>{noCategorizationDetailsText}</div>
              </NoDataContent>
            )
          )}
      </SideBarContent>
    </SideBar>
  );
};

export default React.memo(RpaDetailsPanel);
