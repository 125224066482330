import { createSelector } from "reselect";
import { getAllUsersData } from "./organizations";

export const checkActivityFetching = ({ activity: { fetchingData } }) => fetchingData;

export const checkActivityStatsFetching = ({ activity: { fetchingStats } }) => fetchingStats;

export const getActivityData = ({ activity: { data } }) => data;

export const getActivityStatsData = createSelector(
  [
    ({ activity: { stats } }) => stats,
    getAllUsersData
  ],
  (stats, users) => {
    return stats.map((monthData) => {
      const { month, data } = monthData;

      return {
        month,
        data: Object.keys(data).reduce((acc, key) => {
          const { total, byUsers = [] } = acc[key];

          return {
            ...acc,
            [key]: {
              total,
              byUsers: byUsers
                .map(({ user, ...rest }) => {
                  const found = users.find(({ id }) => id === user.id);

                  return {
                    ...rest,
                    user: found ? {
                      id: found.id,
                      email: found.email,
                      name: found.fullName
                    } : user
                  };
                })
                .sort(
                  ({ user: userA, total: totalA }, { user: userB, total: totalB }) => {
                    const stringA = (userA.name || userA.email || "");

                    const stringB = (userB.name || userB.email || "");

                    return (totalB - totalA) || stringA.localeCompare(stringB);
                  }
                )
            }
          };
        }, data)
      };
    });
  }
);

