import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkSelectedBusinessHasBusinessUsers } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useState } from "react";
import StatsItem from "./lib/StatsItem";
import moment from "moment";

const { PERCENTS_MULTIPLIER, DATETIME_FORMATS } = Constants;

const round = (value) => {
  const rounded = Math.floor(value * PERCENTS_MULTIPLIER);

  return rounded ? `${rounded}%` : (value > 0 ? "<1%" : "0%");
};

const MonthStats = ({ monthData, initialOpened = false }) => {
  const {
    month,
    data: {
      approvedByAi = { total: 0 },
      approvedByAiAndCorrected = { total: 0, byUsers: [] },
      categorizedByAi = { total: 0, byUsers: [] },
      categorizedByAiAndCorrected = { total: 0, byUsers: [] },
      categorizedByAccountants = { total: 0, byUsers: [] },
      categorizedByClients = { total: 0, byUsers: [] },
      clarifiedByClients = { total: 0, byUsers: [] }
    }
  } = monthData;

  const { uiTexts } = useSelector(getTextsData);

  const hasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const [opened, setOpened] = useState(initialOpened);

  const aiTotal = approvedByAi.total + categorizedByAi.total;

  const accountantTotal = approvedByAiAndCorrected.total + categorizedByAiAndCorrected.total + categorizedByAccountants.total;

  const total = aiTotal + accountantTotal + categorizedByClients.total;

  const handleHeaderClick = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  return (
    <div className={Css.monthStats}>
      <div className={Css.header} onClick={handleHeaderClick}>
        <div className={Css.month}>
          <div className={Css.icon}>
            {opened ? <Icons.CaretUp weight="bold" /> : <Icons.CaretDown weight="bold" />}
          </div>
          <span>{moment(month).format(DATETIME_FORMATS.MONTH_AND_YEAR_TEXT_EXT)}</span>
        </div>
        <div className={Css.count}>
          <div className={Css.value}>
            <strong>{total}</strong>
          </div>
          <div className={Css.sign}>=</div>
        </div>
        <div className={Css.count}>
          <div className={Css.value}>
            <strong>{aiTotal}</strong>
            {!!aiTotal && <span>{`(${round(aiTotal / total)})`}</span>}
          </div>
          <div className={Css.sign}>+</div>
        </div>
        <div className={Css.count}>
          <div className={Css.value}>
            <strong>{accountantTotal}</strong>
            {!!accountantTotal && <span>{`(${round(accountantTotal / total)})`}</span>}
          </div>
          {hasBusinessUsers && <div className={Css.sign}>+</div>}
        </div>
        {hasBusinessUsers && (
          <div className={Css.count}>
            <div className={Css.value}>
              <strong>{categorizedByClients.total}</strong>
              {!!categorizedByClients.total && <span>{`(${round(total ? categorizedByClients.total / total : 0)})`}</span>}
            </div>
            <div className={Css.sign} />
          </div>
        )}
      </div>
      {opened && (
        <div className={Css.content}>
          <div className={Css.block}>
            <div className={Css.blockHeader}>
              <div className={Css.label}>{uiTexts.byRoboticAi}</div>
              <div className={Css.value}>
                <span>{aiTotal}</span>
              </div>
            </div>
            <div className={Css.blockContent}>
              <StatsItem
                showUsersStats={false}
                title={uiTexts.automaticallyCategorizedAndApproved}
                total={aiTotal}
                data={approvedByAi} />
              <StatsItem
                title={uiTexts.suggestionsApprovedByTheAccountant}
                total={aiTotal}
                data={categorizedByAi} />
            </div>
          </div>
          <div className={Css.block}>
            <div className={Css.blockHeader}>
              <div className={Css.label}>{uiTexts.byAccountants}</div>
              <div className={Css.value}>
                <span>{accountantTotal}</span>
              </div>
            </div>
            <div className={Css.blockContent}>
              <StatsItem
                title={uiTexts.correctedAutomaticCategorizationsFromTheRoboticAi}
                total={accountantTotal}
                data={approvedByAiAndCorrected} />
              <StatsItem
                title={uiTexts.correctedRoboticAiSuggestions}
                total={accountantTotal}
                data={categorizedByAiAndCorrected} />
              <StatsItem
                title={uiTexts.directCategorizationsAndApprovals}
                total={accountantTotal}
                data={categorizedByAccountants} />
            </div>
          </div>
          {hasBusinessUsers && (
            <div className={Css.block}>
              <div className={Css.blockHeader}>
                <div className={Css.label}>By clients</div>
                <div className={Css.value}>
                  <span>{categorizedByClients.total}</span>
                </div>
              </div>
              <div className={Css.blockContent}>
                <StatsItem
                  title={uiTexts.directlyCategorized}
                  total={categorizedByClients.total}
                  data={categorizedByClients} />
                <div className={Css.title}><span>{uiTexts.notIncludes}</span></div>
                <StatsItem
                  title={uiTexts.clarifiedByClient}
                  total={clarifiedByClients.total}
                  data={clarifiedByClients} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(MonthStats);
