import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import Pages from "nlib/pages/Pages";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";
import useWindowResize from "hooks/useWindowResize";

const { DATETIME_FORMATS: { MONTH_AND_YEAR_TEXT_EXT } } = Constants;

const COMMON_TAB_WIDTH = 128;

const Tab = ({ labelText, data: { label, fromDate, toDate, count, comments }, active, onActive, onClick }) => {
  const ref = useRef();

  const handleClick = useCallback(() => {
    onClick({ fromDate, toDate });
  }, [fromDate, toDate, onClick]);

  useEffect(() => {
    if (active) onActive(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(Css.tab, active && Css.active)}
      onClick={handleClick}>
      <div className={Css.content}>
        <div className={Css.label}>{label}</div>
        <div className={Css.transactions}>
          {Utils.replaceTextVars(labelText, { count })}
        </div>
      </div>
      {!!comments && (
        <Badge
          counter
          className={Css.notification}>
          <Icons.ChatText weight="bold" />
          <span>{comments}</span>
        </Badge>
      )}
    </div>
  );
};

const MonthsTabs = ({ data, disabled, value = {}, dependencies = [], className, onChange }) => {
  const { pathname } = useLocation();

  const [envVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const labelText = useMemo(() => {
    const { route } = Pages.getCurrentRouteInfo(pathname);

    switch (route) {
      case UiRoutes.AUDIT:
        return uiTexts.countIssues;
      case UiRoutes.DOCUMENTS:
        return uiTexts.countDocuments;
      default:
        return uiTexts.countTransactions;
    }
  }, [pathname, uiTexts]);

  const rootRef = useRef();

  const wrapperRef = useRef();

  const [translateX, setTranslateX] = useState(0);

  const [arrowsShown, setArrowsShown] = useState(false);

  const [leftArrowDisabled, setLeftArrowDisabled] = useState(false);

  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);

  const tabs = useMemo(() => {
    return Object
      .keys(data)
      .sort()
      .reverse()
      .filter((key) => data[key].count)
      .map((key) => {
        const month = moment(key);

        return {
          ...data[key],
          id: key,
          fromDate: month.startOf("month").format(Constants.DATETIME_FORMATS.API_DATE),
          toDate: month.endOf("month").format(Constants.DATETIME_FORMATS.API_DATE),
          label: <div>{moment(key).format(MONTH_AND_YEAR_TEXT_EXT)}</div>
        };
      });
  }, [data]);

  const style = useMemo(() => {
    return { transform: `translate(${translateX}px, 0)` };
  }, [translateX]);

  const handleLeftArrowClick = useCallback(() => {
    setTranslateX((prev) => {
      return Math.min(prev + rootRef.current.clientWidth - COMMON_TAB_WIDTH, 0);
    });
  }, []);

  const handleRightArrowClick = useCallback(() => {
    setTranslateX((prev) => {
      return Math.max(
        prev - rootRef.current.clientWidth + COMMON_TAB_WIDTH,
        rootRef.current.clientWidth - wrapperRef.current.scrollWidth
      );
    });
  }, []);

  const handleWindowResize = useCallback(() => {
    if (wrapperRef.current) {
      if (wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth) {
        setArrowsShown(true);
        setLeftArrowDisabled(translateX === 0);
        setRightArrowDisabled(translateX === (rootRef.current.clientWidth - wrapperRef.current.scrollWidth));
      } else {
        setArrowsShown(false);
        setLeftArrowDisabled(false);
        setRightArrowDisabled(false);
        setTranslateX(0);
      }
    }
  }, [translateX]);

  const handleActiveTab = useCallback((element) => {
    const tabRect = element.getBoundingClientRect();

    const rootRect = rootRef.current.getBoundingClientRect();

    setTranslateX(Math.max(
      wrapperRef.current.clientWidth - wrapperRef.current.scrollWidth,
      rootRect.width - tabRect.left - rootRect.left
    ));
  }, []);

  useWindowResize(handleWindowResize);

  useEffect(() => {
    if (wrapperRef.current) {
      setArrowsShown(wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth);
    }
  }, [tabs]);

  useEffect(() => {
    setLeftArrowDisabled(translateX === 0);
  }, [translateX]);

  useEffect(() => {
    if (wrapperRef.current && rootRef.current) {
      setRightArrowDisabled(
        translateX === (rootRef.current.clientWidth - wrapperRef.current.scrollWidth)
      );
    }
  }, [tabs, translateX]);

  useEffect(() => {
    handleWindowResize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envVars.editItem, envVars.editTask, envVars.transactionId, handleWindowResize, ...dependencies]);

  if (!tabs.length) return null;

  return (
    <div className={classNames(Css.monthsTabs, className)} disabled={disabled} ref={rootRef}>
      <div className={Css.wrapper} style={style} ref={wrapperRef}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            data={tab}
            labelText={labelText}
            active={value === `${tab.fromDate}-${tab.toDate}`}
            onClick={onChange}
            onActive={handleActiveTab} />
        ))}
      </div>
      {arrowsShown && (
        <div className={Css.leftArrow} disabled={leftArrowDisabled} onClick={handleLeftArrowClick}>
          <Icons.CaretLeft />
        </div>
      )}
      {arrowsShown && (
        <div className={Css.rightArrow} disabled={rightArrowDisabled} onClick={handleRightArrowClick}>
          <Icons.CaretRight />
        </div>
      )}
    </div>
  );
};

export default React.memo(MonthsTabs);
