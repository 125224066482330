import Constants from "const/Constants";
import React from "react";
import TaskContent from "nlib/common/TaskContent";
import TaskForm from "nlib/common/TaskForm";
import useEnvVars from "hooks/useEnvVars";

const TasksSidePanel = () => {
  const [{ editTask }] = useEnvVars();

  const [editTaskId, sectionName] = (editTask || "").split(".");

  if (!editTaskId) return null;

  return (editTaskId === Constants.NEW_ENTITY_ID)
    ? <TaskForm />
    : <TaskContent scrollToActivity={sectionName === "comments"} taskId={editTaskId} />;
};

export default React.memo(TasksSidePanel);
