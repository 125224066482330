import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Tooltip } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useState } from "react";
import TransactionAmount from "nlib/common/TransactionAmount";
import classNames from "classnames";
import moment from "moment";

const { DATETIME_FORMATS } = Constants;

const { TRANSACTION_TYPES } = DataConstants;

const TableRow = ({ header, entityKey, item, currency }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [opened, setOpened] = useState(false);

  const handleMouseOver = useCallback(() => setOpened(true), []);

  const handleMouseLeave = useCallback(() => setOpened(false), []);

  if (header) {
    return (
      <div className={classNames(Css.row, Css.header)}>
        <div className={Css.cell}>{uiTexts.date}</div>
        <div className={Css.cell}>{uiTexts.amount}</div>
        <div className={Css.cell}>{uiTexts.payee}</div>
        <div className={classNames(Css.cell, Css.icon)} />
      </div>
    );
  }

  return (
    <div className={Css.row}>
      <div
        className={Css.cell}
        title={moment.utc(item.timestamp).format(DATETIME_FORMATS.DATE_TEXT)}>
        {moment.utc(item.timestamp).format(DATETIME_FORMATS.DATE_TEXT)}
      </div>
      <div className={Css.cell}>
        <TransactionAmount
          className={Css.amount}
          amount={item.amount}
          type={item.expense ? TRANSACTION_TYPES.WITHDRAW : TRANSACTION_TYPES.DEPOSIT}
          currency={currency} />
      </div>
      <div
        className={classNames(Css.cell, !item.addressName && Css.muted)}
        title={item.addressName}>
        {item.addressName || uiTexts.noPayee}
      </div>
      <div className={classNames(Css.cell, Css.icon)} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        <Icons.Info />
      </div>
      <Tooltip opened={opened}>
        <div className={Css.tooltip}>
          {!!item[entityKey] && (
            <div><strong>{uiTexts[entityKey]}: </strong><span>{item[entityKey]}</span></div>
          )}
          {!!item.description && (
            <div><strong>{uiTexts.bankDescription}: </strong><span>{item.description}</span> </div>
          )}
          {!!item.gptAiDescription && (
            <div><strong>{uiTexts.aiDescription}: </strong><span>{item.gptAiDescription}</span></div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default React.memo(TableRow);
