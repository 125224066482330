import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import CommentsBlock from "nlib/common/CommentsBlock";
import DataConstants from "const/DataConstants";
import DropDown from "nlib/ui/DropDown";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import DropDownToggle from "nlib/ui/DropDown/lib/DropDownToggle";
import React, { useCallback, useState } from "react";
import classNames from "classnames";

const { COMMENT_TARGET_TYPES: { DOCUMENTS } } = DataConstants;

const CommentCell = (props) => {
  const { documentData } = props;

  const { id: documentId, lastComment, comments, comments: { all, unread } } = documentData;

  const { uiTexts } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const [dropdownOpened, setDropdownOpened] = useState(false);

  const handleCloseClick = useCallback(() => {
    setDropdownOpened(false);
  }, []);

  const handleShowMoreClick = useCallback(() => {
    setDropdownOpened(true);
  }, []);

  return (
    <div
      className={classNames(Css.commentCell,
        businessUser && Css.businessUser,
        businessUser && !!unread && Css.hasUnread)}>
      <DropDown opened={dropdownOpened}>
        {businessUser && (
          <AutoTooltip className={Css.tooltip}>
            <div className={classNames(Css.tooltipContent, !lastComment && Css.noComment)}>
              {lastComment ? lastComment.text : uiTexts.noCommentsYet}
            </div>
          </AutoTooltip>
        )}
        <DropDownToggle
          className={Css.toggle}
          title={businessUser ? undefined : uiTexts.addComment}
          onClick={handleShowMoreClick}>
          {businessUser
            ? (
              <>
                <Icons.ArrowBendUpRight />
                <span>{uiTexts.reply}</span></>
            )
            : (
              <>
                <Icons.ChatText weight={dropdownOpened ? "fill" : "regular"} />
                <Badge
                  counter
                  theme={unread ? "attention" : null}>{all}</Badge>
              </>
            )}
        </DropDownToggle>
        <DropDownContent alignRight className={Css.dropdownContent}>
          <CommentsBlock
            popup
            compact={businessUser}
            className={Css.commentsBlock}
            commentTarget={DOCUMENTS}
            commentTargetId={documentId}
            comments={comments}
            onClose={handleCloseClick} />
        </DropDownContent>
      </DropDown>
    </div>
  );
};

export default React.memo(CommentCell);
