import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import React from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const { PERCENTS_MULTIPLIER } = Constants;

const round = (value) => {
  const rounded = Math.floor(value * PERCENTS_MULTIPLIER);

  return rounded ? `${rounded}%` : (value > 0 ? "<1%" : "0%");
};

const StatsItem = ({ showUsersStats = true, title, total, data }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const integrationServiceData = IntegrationServices.getByValue(integrationService);

  const byUsersTotal = data.byUsers && showUsersStats
    ? data.byUsers.reduce((acc, { total: totalByUser }) => acc + totalByUser, 0) : 0;

  return (
    <div className={Css.statsItem}>
      <div className={Css.blockItem}>
        <div className={Css.text}>
          <span title={title}>{title}</span>
        </div>
        <div className={Css.count}>
          <strong>{data.total}</strong>
          {!!data.total && <span>{`(${round(data.total / total)})`}</span>}
        </div>
      </div>
      {!!data.byUsers && showUsersStats && (
        <>
          {data.byUsers.map(({ user: { name, email }, total: totalByUser }) => {
            return (
              <div key={email} className={classNames(Css.blockItem, Css.userStats)}>
                <div className={Css.text}><span title={name || email}>{name || email}</span></div>
                <div className={Css.count}>
                  <span>{totalByUser}</span>
                  <span>{`(${round(totalByUser / total)})`}</span>
                </div>
              </div>
            );
          })}
          {(data.total > byUsersTotal) && (
            <div className={classNames(Css.blockItem, Css.userStats)}>
              <div className={Css.text}>
                <span>{uiTexts.unknown}</span>
                <span
                  data-tooltip={Utils.replaceTextVars(messages.activityStatsUnknownDescription, {
                    serviceName: integrationServiceData.label
                  })}>
                  <Icons.Question />
                </span>
              </div>
              <div className={Css.count}>
                <span>{data.total - byUsersTotal}</span>
                <span>
                  {`(${round((data.total - byUsersTotal) / total)})`}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(StatsItem);
