import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { DebounceInput, Tab, Tabs } from "nlib/ui";
import { checkIsBusinessUser, getUserData } from "selectors/user";
import { checkTasksFetching, checkTasksReadyToDisplay, getTasksData } from "selectors/tasks";
import { getAllUsersData } from "selectors/organizations";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BoardView from "./lib/BoardView";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import FilterByUser from "./lib/FilterByUser";
import ListView from "./lib/ListView";
import MonthsTabs from "./lib/MonthsTabs";
import MonthsTabsVertical from "./lib/MonthsTabsVertical";
import NoDataContent from "nlib/common/NoDataContent";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import Preloader from "nlib/ui/Preloader";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import TasksActions from "actions/TasksActions";
import TasksSidePanel from "nlib/common/TasksSidePanel";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const VIEW_NAMES = {
  LIST: "list",
  PERIODS: "periods",
  BOARD: "board"
};

const TASK_TARGET_USERS = {
  ACCOUNTANT: "accountant",
  CLIENT: "client",
  CURRENT_USER: "currentUser"
};

const TasksPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { section: currentView = VIEW_NAMES.LIST } = useParams();

  const [envVars, setEnvVars] = useEnvVars();

  const { fromDate, toDate } = envVars;

  const { uiTexts, messages } = useSelector(getTextsData);

  const allUsersData = useSelector(getAllUsersData);

  const allTasksData = useSelector(getTasksData);

  const tasksFetching = useSelector(checkTasksFetching);

  const userData = useSelector(getUserData);

  const fetchingData = tasksFetching;

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const businessUser = useSelector(checkIsBusinessUser);

  const tasksReadyToDisplay = useSelector(checkTasksReadyToDisplay);

  const [searchText, setSearchText] = useState(envVars.text || "");

  const filteredTasksData = useMemo(() => {
    if (!envVars.text) return allTasksData;

    const searchString = envVars.text.trim().toLowerCase();

    return allTasksData.filter(({ preview, title, text, targetUser, createdBy }) =>
      [preview, title, text, targetUser?.fullName, targetUser?.email, createdBy?.fullName, createdBy?.email]
        .some((item) => (item || "").toLowerCase().includes(searchString)));
  }, [envVars.text, allTasksData]);

  const tasksFilteredByUser = useMemo(() => {
    if (!envVars.targetUser) return filteredTasksData;

    switch (envVars.targetUser) {
      case TASK_TARGET_USERS.ACCOUNTANT:
        return filteredTasksData.filter(({ targetUser }) => !targetUser || UserRoles.checkIsAccountant(targetUser.role));
      case TASK_TARGET_USERS.CLIENT:
        return filteredTasksData.filter(({ targetUser }) => targetUser && UserRoles.checkIsBusiness(targetUser.role));
      case TASK_TARGET_USERS.CURRENT_USER:
        return filteredTasksData.filter(({ targetUser }) => targetUser ? (targetUser?.id === userData.id) : !businessUser);
      default:
        return filteredTasksData.filter(({ targetUser }) => {
          if (!targetUser) {
            const taskTargetUser = Utils.arrayFindById(allUsersData, envVars.targetUser);

            return taskTargetUser && !UserRoles.checkIsBusiness(taskTargetUser.role);
          }

          return targetUser.id === envVars.targetUser;
        });
    }
  }, [envVars.targetUser, filteredTasksData, userData.id, businessUser, allUsersData]);

  const filteredTasksByPeriod = useMemo(() => {
    if (!fromDate && !toDate) return tasksFilteredByUser;

    return tasksFilteredByUser.filter(({ startDate }) => {
      const momentDate = moment.utc(startDate);

      return (!fromDate || momentDate.isSameOrAfter(moment.utc(fromDate).startOf("day")))
        && (!toDate || momentDate.isSameOrBefore(moment.utc(toDate).endOf("day")));
    });
  }, [fromDate, tasksFilteredByUser, toDate]);

  const handleAddTaskButtonClick = useCallback(() => {
    setEnvVars({ editTask: Constants.NEW_ENTITY_ID, editItem: null });
  }, [setEnvVars]);

  const handleViewChange = useCallback((nextView) => {
    history.push(`/${selectedBusinessId}${UiRoutes.TASKS}/${nextView}`);
    Utils.storageValue(Constants.LS_KEYS.TASKS_VIEW_LAST_TAB, nextView);
  }, [history, selectedBusinessId]);

  const handleSearchChange = useCallback((value) => {
    setSearchText(value);
  }, []);

  const handleSearchComplete = useCallback((value) => {
    setEnvVars({ text: value.trim() || null });
  }, [setEnvVars]);

  const handleResetClick = useCallback(() => {
    setEnvVars({ text: null, targetUser: null, fromDate: null, toDate: null });
  }, [setEnvVars]);

  useLayoutEffect(() => {
    dispatch(TasksActions.fetchTasksList());
  }, [dispatch]);

  useEffect(() => {
    setSearchText(envVars.text || "");
  }, [envVars.text]);

  useEffect(() => {
    if (!fetchingData && fromDate && toDate && !filteredTasksByPeriod.length) {
      setEnvVars({ fromDate: null, toDate: null });
    }
  }, [fetchingData, fromDate, toDate, filteredTasksByPeriod.length, setEnvVars]);

  useEffect(() => {
    if (currentView) {
      Utils.storageValue(Constants.LS_KEYS.TASKS_VIEW_LAST_TAB, currentView);
    }
  }, [currentView]);

  if (!tasksReadyToDisplay) return <Preloader />;

  return (
    <>
      <Page className={Css.tasksPage}>
        <PageContent>
          <PageHeader className={Css.pageHeader} />
          <div className={Css.subHeader}>
            <div>
              {!businessUser && (
                <Tabs current={currentView} onChange={handleViewChange}>
                  <Tab value={VIEW_NAMES.LIST}>
                    <Icons.List />
                    <span>{uiTexts.list}</span>
                  </Tab>
                  <Tab value={VIEW_NAMES.PERIODS}>
                    <Icons.Checks />
                    <span>{uiTexts.periods}</span>
                  </Tab>
                  <Tab value={VIEW_NAMES.BOARD}>
                    <Icons.Kanban />
                    <span>{uiTexts.board}</span>
                  </Tab>
                </Tabs>
              )}
            </div>
            {!!allTasksData.length && (
              <div className={Css.filters}>
                {(!!envVars.text || envVars.targetUser || envVars.fromDate || envVars.toDate) && (
                  <Button
                    large outline danger
                    className={Css.reset}
                    icon={Icons.X}
                    onClick={handleResetClick}>
                    {uiTexts.resetFilters}
                  </Button>
                )}
                <DebounceInput
                  cleanable
                  placeholder={uiTexts.searchTask}
                  iconBefore={Icons.MagnifyingGlass}
                  className={Css.search}
                  value={searchText}
                  onChange={handleSearchChange}
                  onInputComplete={handleSearchComplete} />
                <FilterByUser className={Css.filter} />
                {((envVars.editTask || "").split(".")[0] !== Constants.NEW_ENTITY_ID) && (
                  <Button
                    large primary
                    className={Css.button}
                    icon={Icons.Plus}
                    onClick={handleAddTaskButtonClick}>
                    {uiTexts.addNewTask}
                  </Button>
                )}
              </div>
            )}
          </div>
          {allTasksData.length
            ? (
              (currentView === VIEW_NAMES.BOARD && !businessUser)
                ? (
                  <>
                    <MonthsTabs data={tasksFilteredByUser} />
                    <BoardView tasksData={filteredTasksByPeriod} />
                  </>
                )
                : (
                  <div className={Css.content}>
                    {(currentView === VIEW_NAMES.PERIODS && !businessUser) && (
                      <MonthsTabsVertical
                        data={tasksFilteredByUser} />
                    )}
                    <ListView
                      verticalMonthTabsShowed={currentView === VIEW_NAMES.PERIODS && !businessUser}
                      tasksData={filteredTasksByPeriod} />
                  </div>
                )
            )
            : (fetchingData ? <Preloader absolute /> : (
              <NoDataContent
                description={messages.tasksBlockPlaceholder}>
                <Button
                  large primary
                  icon={Icons.Plus}
                  onClick={handleAddTaskButtonClick}>
                  {uiTexts.addNewTask}
                </Button>
              </NoDataContent>
            ))}
        </PageContent>
      </Page>
      <TasksSidePanel />
    </>
  );
};

export default React.memo(TasksPage);
