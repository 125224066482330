import Css from "./style.module.scss";

import { Badge } from "nlib/ui";
import { getSelectedBusinessUsersData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import ActivityContainer from "nlib/common/Activity/lib/ActivityContainer";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import moment from "moment";

const {
  MENTION_TRIGGER_CHAR,
  DATETIME_FORMATS: { DATETIME_TEXT }
} = Constants;

const { COMMENT_TYPES: { NOTE } } = DataConstants;

const MENTION_MATCH_PATTERN = new RegExp(`(${MENTION_TRIGGER_CHAR}\\S* \\([^)]*\\))|(${MENTION_TRIGGER_CHAR}\\S*)`);

const Comment = ({ activity = {}, onUserMention }) => {
  const { id: currentUserId } = useSelector(getUserData);

  const { readBy = [], createdBy: { email, fullName = email } = {}, createdAt, text, type } = activity;

  const { uiTexts } = useSelector(getTextsData);

  const usersData = useSelector(getSelectedBusinessUsersData);

  const usersEmails = useMemo(() => {
    return usersData.map((item) => item.email + (item.fullName === item.email ? "" : ` (${item.fullName})`));
  }, [usersData]);

  const handleMentionLinkClick = useCallback((event) => {
    const { currentTarget: { dataset: { mention } } } = event;

    if (!mention) return;

    onUserMention(mention);
  }, [onUserMention]);

  const partials = useMemo(() => {
    if (!onUserMention) return text;

    return text
      .split(MENTION_MATCH_PATTERN)
      .filter((part) => !!part)
      .map((part, index) => {
        const key = `${part}-${index}`;

        if (!MENTION_MATCH_PATTERN.test(part) || !usersEmails.includes(part.slice(1))) {
          return (<span key={key}>{part}</span>);
        }

        return (
          <span
            key={key}
            data-mention={part}
            className={Css.mention}
            onClick={handleMentionLinkClick}>{part}</span>
        );
      });
  }, [handleMentionLinkClick, onUserMention, text, usersEmails]);

  const infoContent = useMemo(() => {
    return (
      <>
        <div className={Css.title}>
          <span
            data-mention={onUserMention
              ? `${MENTION_TRIGGER_CHAR}${email + (fullName === email || !fullName ? "" : ` (${fullName})`)}`
              : undefined}
            onClick={handleMentionLinkClick}>{fullName}</span>
          {type === NOTE && <Badge className={Css.badge}>{uiTexts.note}</Badge>}
        </div>
        <div className={Css.date}>
          {moment(createdAt).format(DATETIME_TEXT)}
        </div>
      </>
    );
  }, [createdAt, email, fullName, handleMentionLinkClick, onUserMention, type, uiTexts.note]);

  return (
    <ActivityContainer
      className={classNames(Css.comment, type === NOTE && Css.typeNote, !readBy.includes(currentUserId) && Css.notRead)}
      avatarTitle={fullName}
      infoContent={infoContent}>
      <div className={Css.text}>{partials}</div>
    </ActivityContainer>
  );
};

export default React.memo(Comment);
