import Css from "./style.module.scss";

import { checkMatchesFetching, getGlobalStats } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DocumentsActions from "actions/DocumentsActions";
import DocumentsStatusFilter from "./lib/DocumentsStatusFilter";
import DocumentsTable from "./lib/DocumentsTable";
import DuplicatedDocumentsWindow from "./lib/DuplicatedDocumentsWindow";
import EditDocument from "./lib/EditDocument";
import HeaderButtons from "./lib/HeaderButtons";
import MonthsTabs from "nlib/common/MonthsTabs";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Sidebar from "./lib/Sidebar";
import Utils from "utils/Utils";
import useAvailableWidth from "hooks/useAvailableWidth";
import useDocumentUtils from "hooks/useDocumentUtils";
import useDocuments from "hooks/useDocuments";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useShowModal from "hooks/useShowModal";
import useUploadDocuments from "hooks/useUploadDocuments";

const {
  STATUSES: { TO_REVIEW, EXCLUDED, TO_REPORT, EXPORTED }
} = DataConstants;

const SIMPLIFIED_LAYOUT_MAX_WIDTH = 1020;

const DocumentsPage = () => {
  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const contentRef = useRef();

  const [envVars, setEnvVars] = useEnvVars();

  const [editDocumentId] = envVars.editItem ? envVars.editItem.split(".") : [];

  const matchesFetching = useSelector(checkMatchesFetching);

  const { messages } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const globalStats = useSelector(getGlobalStats);

  const { openPairedTransactionsWindow } = useDocumentUtils();

  const {
    fetching: documentsFetching,
    data: documentsData,
    refetch: refetchDocuments
  } = useDocuments();

  const [
    duplicatedDocumentsModalData,
    showDuplicatedDocumentModal,
    onCloseManageDuplicationsModal
  ] = useShowModal();

  const { containerRef, availableWidth } = useAvailableWidth([documentsFetching, envVars.editTask, envVars.editItem]);

  const uploadDocuments = useUploadDocuments();

  const [uploadDocumentsWindowOpened, setUploadDocumentsWindowOpened] = useState(false);

  const simplifyLayout = availableWidth <= SIMPLIFIED_LAYOUT_MAX_WIDTH;

  const fetchingData = documentsFetching || matchesFetching;

  const filtersEnabled = [
    envVars.text, envVars.fromDate, envVars.toDate, envVars.accountId, envVars.type
  ].find(Boolean);

  const byMonthData = useMemo(() => {
    return [TO_REVIEW, EXCLUDED, TO_REPORT, EXPORTED].reduce((result, status) => {
      const sectionData = (globalStats.documents || {})[status] || {};

      if (sectionData.byMonth) {
        Object.entries(sectionData.byMonth).forEach(([month, { count, comments }]) => {
          const { count: prevCount = 0, comments: prevComments = 0 } = result[month] || {};

          result[month] = { count: count + prevCount, comments: comments + prevComments };
        });
      }

      return result;
    }, {});
  }, [globalStats.documents]);

  const openDocument = useCallback(async(id) => {
    setEnvVars({ editTask: null });

    const [documentId] = (id || "").split(".");

    const documentData = Utils.arrayFindById(documentsData, documentId);

    if (!documentData || documentData.status === DataConstants.STATUSES.TO_EXTRACT) return;

    if (documentData.duplicatedDocumentsIds && documentData.duplicatedDocumentsIds.length) {
      const modalResult = await showDuplicatedDocumentModal(documentData);

      if (modalResult) {
        const { deleteDocument, duplicatedDocumentsIds } = modalResult;

        if (deleteDocument) {
          const deleteModalResult = await showCommonModal({ text: messages.documentDeleteConfirm, confirm: true });

          if (deleteModalResult) {
            await dispatch(DocumentsActions.deleteDocument(id));
            refetchDocuments();
          }
        }
        if (duplicatedDocumentsIds) {
          await dispatch(DocumentsActions.editDocument(documentId, { duplicatedDocumentsIds: [] }, false, false, false, true));
          setEnvVars({ editItem: id });
          refetchDocuments();
        }
      }

      return;
    }

    setEnvVars({ editItem: id });
  }, [documentsData, messages, dispatch, refetchDocuments, setEnvVars, showCommonModal, showDuplicatedDocumentModal]);

  const handlePairTransactionsLinkClick = useCallback(async(id) => {
    const documentData = Utils.arrayFindById(documentsData, id);

    if (documentData) {
      const result = await openPairedTransactionsWindow(documentData);

      if (result) refetchDocuments();
    }
  }, [documentsData, openPairedTransactionsWindow, refetchDocuments]);

  const handleEditDocumentWindowClose = useCallback((refetchData) => {
    setEnvVars({ editItem: null });

    if (refetchData) refetchDocuments(true);
  }, [refetchDocuments, setEnvVars]);

  const handleStatusTabClick = useCallback((value) => {
    if (envVars.status === value) refetchDocuments();
  }, [envVars.status, refetchDocuments]);

  const handleMonthTabChange = useCallback((params) => {
    if (params.fromDate !== envVars.fromDate || params.toDate !== envVars.toDate) {
      setEnvVars({ ...params, text: null, type: null, accountId: null });
    } else {
      refetchDocuments();
    }
  }, [setEnvVars, envVars.fromDate, envVars.toDate, refetchDocuments]);

  const handleUploadDocumentsOpen = useCallback(() => {
    setUploadDocumentsWindowOpened(true);
  }, []);

  const handleSideBarClose = useCallback(async(result) => {
    setUploadDocumentsWindowOpened(false);

    if (!result) return;

    const response = await uploadDocuments(result);

    if (response) refetchDocuments();
  }, [refetchDocuments, uploadDocuments]);

  return (
    <>
      <Page className={Css.documentsPage} pageRef={containerRef}>
        <PageContent ref={contentRef}>
          <PageHeader>
            {(!!documentsData.length || (!envVars.status || filtersEnabled)) && (
              <HeaderButtons
                disabled={fetchingData}
                hideUploadButton={uploadDocumentsWindowOpened}
                onUploadDocumentsOpen={handleUploadDocumentsOpen} />
            )}
          </PageHeader>
          {!userData.guestUser && (
            <MonthsTabs
              disabled={fetchingData}
              data={byMonthData}
              value={`${envVars.fromDate}-${envVars.toDate}`}
              onChange={handleMonthTabChange} />
          )}
          <DocumentsStatusFilter
            disabled={fetchingData}
            simplifyLayout={simplifyLayout}
            onClick={handleStatusTabClick} />
          <DocumentsTable
            fetchingData={fetchingData}
            simplifyLayout={simplifyLayout}
            documentsData={documentsData}
            uploadDocumentsWindowOpened={uploadDocumentsWindowOpened}
            openDocument={openDocument}
            refetchDocuments={refetchDocuments}
            onUploadDocumentsOpen={handleUploadDocumentsOpen}
            onPairTransactionsLinkClick={handlePairTransactionsLinkClick} />
        </PageContent>
      </Page>
      {!!editDocumentId && (
        <EditDocument
          key={editDocumentId}
          documentId={editDocumentId}
          openDocument={openDocument}
          refetchDocuments={refetchDocuments}
          onClose={handleEditDocumentWindowClose} />
      )}
      {uploadDocumentsWindowOpened && (
        <Sidebar onClose={handleSideBarClose} />
      )}
      {duplicatedDocumentsModalData && (
        <DuplicatedDocumentsWindow
          documentData={duplicatedDocumentsModalData}
          onClose={onCloseManageDuplicationsModal} />
      )}
    </>
  );
};

export default React.memo(DocumentsPage);
