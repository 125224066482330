import {
  getAuditData,
  getSelectedBusinessAuditFilters
} from "selectors/audit";
import { getSelectedBusinessData } from "selectors/businesses";
import { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import Utils from "utils/Utils";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const {
  AUDIT_SUBSECTIONS_ROUTES,
  AUDIT_AMOUNT_DEVIATION_FILTERS,
  AUDIT_DEVIATION_DEFAULT_VALUE,
  AUDIT_DEVIATION_DEFAULT_MIN_VALUE
} = Constants;

const { GREATER_THAN_OR_EQUAL } = AUDIT_AMOUNT_DEVIATION_FILTERS;

const useAudit = (currentSection, currentRoute, skip) => {
  const dispatch = useDispatch();

  const data = useSelector(getAuditData);

  const { auditSettings } = useSelector(getSelectedBusinessData);

  const [envVars] = useEnvVars();

  const firstCallRef = useRef(true);

  const {
    compareAmountBy: defaultCompareAmountBy = GREATER_THAN_OR_EQUAL,
    amount: defaultAmount = AUDIT_DEVIATION_DEFAULT_VALUE,
    minAmount: defaultMinAmount = AUDIT_DEVIATION_DEFAULT_MIN_VALUE
  } = useSelector(getSelectedBusinessAuditFilters);

  const availableSections = useMemo(() => JSON.stringify(auditSettings), [auditSettings]);

  const {
    text,
    type,
    month,
    sortBy,
    editItem,
    accountId,
    sortOrder,
    page = "1",
    amount = defaultAmount,
    minAmount = defaultMinAmount,
    compareAmountBy = defaultCompareAmountBy,
    pageSize = `${Constants.TABLE_PAGE_SIZE}`,
    toDate = month ? moment(month).endOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
    fromDate = month ? moment(month).startOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined
  } = envVars;

  const [searchTransactionId] = (editItem || "").split(".");

  const skipFetch = skip
    || !currentSection
    || (currentRoute === AUDIT_SUBSECTIONS_ROUTES.AMOUNT_DEVIATION && !compareAmountBy);

  const fetchData = useCallback(async(clearList = false, backgroundUpdate = false) => {
    if (skipFetch) return null;

    firstCallRef.current = false;

    const betweenType = compareAmountBy === AUDIT_AMOUNT_DEVIATION_FILTERS.BETWEEN;

    const parsedAmount = Utils.parseFloat(amount);

    const parsedMinAmount = Utils.parseFloat(minAmount);

    const result = await dispatch(AuditActions.fetchAuditList({
      currentSection,
      currentRoute,
      clearList,
      backgroundUpdate,
      filters: {
        text: searchTransactionId || text,
        fromDate,
        toDate,
        accountId,
        type,
        compareAmountBy,
        amount: betweenType ? Math.min(parsedAmount, parsedMinAmount) : parsedAmount,
        maxAmount: betweenType ? Math.max(parsedAmount, parsedMinAmount) : undefined
      },
      sortings: sortBy ? { id: sortBy, desc: sortOrder === "desc" } : null,
      offset: (page - 1) * pageSize,
      limit: +pageSize,
      dependencies: [
        availableSections
      ]
    }));

    return result;
  }, [
    page,
    text,
    type,
    amount,
    sortBy,
    toDate,
    fromDate,
    pageSize,
    accountId,
    minAmount,
    skipFetch,
    sortOrder,
    currentRoute,
    currentSection,
    compareAmountBy,
    availableSections,
    searchTransactionId,
    dispatch
  ]);

  const refetch = useCallback((backgroundUpdate) => {
    fetchData(false, backgroundUpdate);
  }, [fetchData]);

  useLayoutEffect(() => {
    fetchData(firstCallRef.current);
  }, [fetchData]);

  useLayoutEffect(() => {
    return Utils.setInterval(
      () => fetchData(false, true), Constants.DATA_LIST_UPDATE_INTERVAL
    );
  }, [fetchData]);

  return { data, refetch };
};

export default useAudit;
