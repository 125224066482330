import Css from "./style.module.scss";

import { Document, Page, pdfjs } from "react-pdf";
import { DocumentBlockLayer } from "./lib/DocumentBlockLayer";
import { rotateBoundingBox } from "nlib/common/EditDocumentContent/utils";
import { useDispatch } from "react-redux";
import AllAvailableBlocks from "./lib/AllAvailableBlocks";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import useDocumentContext from "hooks/useDocumentContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ROTATE_RIGHT = 90;

const ROTATE_LEFT = 270;

const SCROLL_WIDTH = 24;

const FLIP_DIMENSIONS_ANGLE = [ROTATE_LEFT, ROTATE_RIGHT];

const DocumentRenderer = (props) => {
  const {
    containerWidth,
    firstPageSided,
    pageWidthScale,
    pageFitScale,
    pagesData,
    scrollContainerRef,
    documentScale,
    numPages,
    setPagesData,
    setNumPages,
    setDocumentScale
  } = props;

  const dispatch = useDispatch();

  const prevDocumentScale = useRef();

  const { attachment, ocrMeta, localState: { activeField } } = useDocumentContext();

  const [fileUrl, setFileUrl] = useState(null);

  const [dragStart, setDragStart] = useState(null);

  const wordBlocks = useMemo(() => {
    if (!ocrMeta.wordBlocks) return [];

    return ocrMeta.wordBlocks.map((block) => {
      const { boundingBox: { page: index } } = block;

      const pageData = pagesData[index];

      if (!pageData) return null;

      return {
        ...block,
        boundingBox: rotateBoundingBox({
          boundingBox: block.boundingBox,
          angle: Utils.normalizeAngle(ocrMeta.pages?.[index]?.angle || 0),
          width: pageData.width || 0,
          height: pageData.height || 0
        })
      };
    }).filter(Boolean);
  }, [ocrMeta.pages, ocrMeta.wordBlocks, pagesData]);

  const contentWidth = (firstPageSided ? pagesData[0]?.height : pagesData[0]?.width) || 0;

  const pageHeights = useMemo(() => {
    return pagesData.map((data) => {
      return (FLIP_DIMENSIONS_ANGLE.includes(data?.rotate) ? data?.width : data?.height) || 0;
    });
  }, [pagesData]);

  const fetchFileUrl = useCallback(async() => {
    const result = await dispatch(MainApiActions.fetchAttachmentUrl(attachment));

    setFileUrl(result);
  }, [attachment, dispatch]);

  useLayoutEffect(() => {
    if (!fileUrl) fetchFileUrl();
  }, [fileUrl, dispatch, fetchFileUrl]);

  const handleDocumentLoadSuccess = useCallback((data) => {
    setNumPages(data.numPages);
  }, [setNumPages]);

  const handlePageRenderSuccess = useCallback((data) => {
    const { rotate: initialRotate, height, width, originalHeight, originalWidth, _pageIndex: index } = data;

    const rotate = initialRotate || Utils.normalizeAngle(ocrMeta.pages?.[index]?.angle || 0);

    setPagesData((prev) => {
      const newData = [...prev];

      newData[index] = { rotate, height, width, originalHeight, originalWidth };

      return newData;
    });
  }, [ocrMeta.pages, setPagesData]);

  const handleMouseDown = useCallback(({ clientX, clientY }) => {
    if (activeField) return;

    const { scrollTop, scrollLeft } = scrollContainerRef.current;

    setDragStart({ clientX, clientY, scrollTop, scrollLeft });
  }, [activeField, scrollContainerRef]);

  const handleMouseUp = useCallback(() => {
    setDragStart(null);
  }, []);

  const handleMouseMove = useCallback((event) => {
    if (dragStart) {
      const { clientX, clientY, scrollTop, scrollLeft } = dragStart;

      scrollContainerRef.current.scrollTop = scrollTop + clientY - event.clientY;
      scrollContainerRef.current.scrollLeft = scrollLeft + clientX - event.clientX;
    }
  }, [dragStart, scrollContainerRef]);

  useEffect(() => {
    if (!isNaN(documentScale) && prevDocumentScale.current && prevDocumentScale.current !== documentScale) {
      const { scrollTop, scrollLeft } = scrollContainerRef.current;

      scrollContainerRef.current.scrollTop = scrollTop * documentScale / prevDocumentScale.current;
      scrollContainerRef.current.scrollLeft = scrollLeft * documentScale / prevDocumentScale.current;
      prevDocumentScale.current = documentScale;
    }
  }, [documentScale, scrollContainerRef]);

  useEffect(() => {
    if (documentScale === "pageFit") setDocumentScale(pageFitScale);
    if (documentScale === "pageWidth") setDocumentScale(pageWidthScale);
  }, [documentScale, pageFitScale, pageWidthScale, setDocumentScale]);

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseUp]);

  return (
    <div
      className={classNames(Css.documentRenderer, !activeField && Css.grabbable)}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}>
      {!!fileUrl && (
        <div className={Css.document}>
          <Document
            loading={null}
            file={fileUrl}
            onLoadSuccess={handleDocumentLoadSuccess}>
            {[...(new Array(numPages || 0))].map((item, pageIndex) => {
              return (
                <Page
                  key={String(pageIndex + 1)}
                  rotate={pagesData[pageIndex]?.rotate || 0}
                  scale={isNaN(documentScale) ? undefined : documentScale}
                  width={documentScale ? undefined : (containerWidth - SCROLL_WIDTH)}
                  pageIndex={pageIndex}
                  onRenderSuccess={handlePageRenderSuccess}
                  renderAnnotationLayer={false}
                  renderTextLayer={false} />
              );
            })}
          </Document>
          <AllAvailableBlocks
            wordBlocks={wordBlocks}
            width={contentWidth}
            docPageHeights={pageHeights} />
          <DocumentBlockLayer
            wordBlocks={wordBlocks}
            scrollContainerRef={scrollContainerRef}
            docPageHeights={pageHeights}
            pagesData={ocrMeta.pages}
            width={contentWidth} />
        </div>
      )}
    </div>
  );
};

export default React.memo(DocumentRenderer);
