import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import * as Yup from "yup";
import { checkArchiveFetching } from "selectors/archive";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import Activity from "nlib/common/Activity";
import ArchiveActions from "actions/ArchiveActions";
import Attachments from "nlib/common/Attachments";
import Button from "nlib/ui/Button";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import DatePickerInput from "nlib/common/DatePickerInput";
import DropArea from "nlib/common/DropArea";
import FileTypedIcon from "nlib/common/FileTypedIcon";
import FormLabel from "nlib/common/FormLabel";
import Input from "nlib/ui/Input";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Select from "nlib/ui/Select";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "nlib/common/SideBar";
import TagsInput from "nlib/ui/TagsInput";
import Utils from "utils/Utils";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const {
  ARCHIVE_ATTACHMENT_TYPES: { TAX_STATEMENT, AGREEMENT, BANK_STATEMENT, OTHER }
} = DataConstants;

const { COMMENT_TARGET_TYPES: { ARCHIVE } } = DataConstants;

const EMAIL_ADDRESS_PREFIX = "fs.";

const VALIDATION_SCHEMA = Yup.object().shape({
  id: Yup.string().nullable(),
  type: Yup.string().required(),
  fromDate: Yup.string(),
  toDate: Yup.string(),
  description: Yup.string().trim(),
  tags: Yup.array().of(Yup.string().trim()),
  path: Yup.array().of(Yup.string().trim())
});

const BANK_STATEMENT_VALIDATION_SCHEMA = Yup.object().shape({
  id: Yup.string().nullable(),
  type: Yup.string().required(),
  fromDate: Yup.string().required(),
  toDate: Yup.string().required(),
  description: Yup.string().trim(),
  tags: Yup.array().of(Yup.string().trim()),
  path: Yup.array().of(Yup.string().trim())
});

const INITIAL_DATA = {
  fromDate: "",
  toDate: "",
  type: null,
  tags: [],
  description: ""
};

const NAME_MAX_LENGTH = 40;

const Sidebar = ({ scrollToActivity, editData, onClose }) => {
  const activitySectionRef = useRef();

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const { meta: { emailAddress } } = useSelector(getSelectedBusinessData);

  const fetching = useSelector(checkArchiveFetching);

  const initialData = useMemo(() => {
    const data = editData || INITIAL_DATA;

    return {
      fromDate: data.fromDate,
      toDate: data.toDate,
      type: data.type,
      description: data.description,
      tags: data.tags
    };
  }, [editData]);

  const [files, setFiles] = useState([]);

  const [fromDate, setFromDate] = useState(initialData.fromDate);

  const [toDate, setToDate] = useState(initialData.toDate);

  const [type, setType] = useState(initialData.type);

  const [tags, setTags] = useState(initialData.tags);

  const [description, setDescription] = useState(initialData.description);

  const [activityLoaded, setActivityLoaded] = useState(!editData?.id);

  const typeOptions = useMemo(() => [
    { value: AGREEMENT, label: uiTexts.agreement },
    { value: TAX_STATEMENT, label: uiTexts.taxStatement },
    { value: BANK_STATEMENT, label: uiTexts.bankStatement },
    { value: OTHER, label: uiTexts.other }
  ], [uiTexts]);

  const submitForm = useCallback(() => {
    onClose({
      ...(editData
        ? { id: editData.id, path: editData.path }
        : { files: files.map((item) => item.file) }),
      fromDate,
      toDate,
      type,
      description,
      tags
    });
  }, [description, editData, files, fromDate, tags, toDate, type, onClose]);

  const checkFormValidity = useCallback(() => {
    if (editData || files.length) {
      const state = { type, fromDate, toDate, description, tags };

      return type === BANK_STATEMENT
        ? BANK_STATEMENT_VALIDATION_SCHEMA.isValidSync(state)
        : VALIDATION_SCHEMA.isValidSync(state);
    }

    return false;
  }, [editData, files, description, fromDate, tags, toDate, type]);

  const hasChanges = useMemo(() => {
    return !Utils.deepEqual(initialData, { fromDate, toDate, type, description, tags });
  }, [initialData, fromDate, toDate, type, description, tags]);

  const handleCloseButtonClick = useCallback(async() => {
    if (editData) {
      if (hasChanges && checkFormValidity()) {
        const result = await showCommonModal({
          text: messages.unsavedChangesWarning,
          headerText: uiTexts.warning,
          confirm: true,
          okButtonText: uiTexts.yes,
          cancelButtonText: uiTexts.no
        });

        if (result) {
          submitForm();

          return;
        }
      }
    }

    onClose();
  }, [hasChanges, editData, messages, uiTexts, onClose, showCommonModal, submitForm, checkFormValidity]);

  const handleFilesDrop = useCallback((newFiles) => {
    if (newFiles.length) {
      const newFilesItems = newFiles.map((file) => ({
        file,
        originalName: file.name,
        id: uuid(),
        createdAt: Date.now()
      }));

      setFiles((prev) => [...prev, ...newFilesItems]);
    } else {
      setFiles([]);
    }
  }, []);

  const handleAttachmentDelete = useCallback((id) => {
    setFiles((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const handleTypeChange = useCallback((value) => {
    setType(value);
  }, []);

  const handleFromDateChange = useCallback((value) => {
    const formattedValue = value ? Utils.formatApiDate(value) : "";

    setFromDate(formattedValue);
    setToDate((prev) => {
      return !value || !prev || moment.utc(prev).isBefore(moment.utc(formattedValue)) ? formattedValue : prev;
    });
  }, []);

  const handleToDateChange = useCallback((value) => {
    const formattedValue = value ? Utils.formatApiDate(value) : "";

    setToDate(formattedValue);
    setFromDate((prev) => {
      return !value || !prev || moment.utc(formattedValue).isBefore(moment.utc(prev)) ? formattedValue : prev;
    });
  }, []);

  const handleDescriptionChange = useCallback((value) => {
    setDescription(value);
  }, []);

  const handleTagsChange = useCallback((value) => {
    setTags(value);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const handleFileInfoClick = useCallback(() => {
    dispatch(ArchiveActions.downloadFile(editData.id, editData.attachment));
  }, [editData, dispatch]);

  const handleEmailClick = useCallback(() => {
    navigator.clipboard.writeText(EMAIL_ADDRESS_PREFIX + emailAddress);
    toast.info(uiTexts.copiedToClipboard);
  }, [emailAddress, uiTexts.copiedToClipboard]);

  useEffect(() => {
    if (scrollToActivity && activitySectionRef.current) {
      activitySectionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [scrollToActivity, activityLoaded]);

  useLayoutEffect(() => {
    if (editData?.id) {
      setActivityLoaded(false);
      dispatch(CommentsActions.fetchComments(ARCHIVE, editData.id, true)).then(() => {
        setActivityLoaded(true);
      });
    }
  }, [dispatch, editData?.id]);

  return (
    <SideBar className={Css.sidebar}>
      <SideBarHeader onCloseClick={handleCloseButtonClick}>
        {editData ? uiTexts.editFile : uiTexts.uploadFiles}
      </SideBarHeader>
      <SideBarContent>
        {activityLoaded
          ? (
            <>
              {editData
                ? (
                  <div className={Css.fileInfo} onClick={handleFileInfoClick}>
                    <FileTypedIcon className={Css.icon} fileName={editData.attachment.originalName} />
                    <div className={Css.name} title={editData.attachment.originalName}>
                      {Utils.stringShortener(editData.attachment.originalName, NAME_MAX_LENGTH)}
                    </div>
                  </div>
                )
                : (
                  <>
                    <Attachments
                      showAll
                      useConfirm={false}
                      attachments={files}
                      disabled={fetching}
                      onDelete={handleAttachmentDelete} />
                    <DropArea multiple showAccepted={false} onDrop={handleFilesDrop} />
                    <div className={Css.row}>
                      <div className={Css.col}>
                        <div>{messages.autoUploadFiles}</div>
                        <div className={Css.email} onClick={handleEmailClick}>
                          <span>{EMAIL_ADDRESS_PREFIX + emailAddress}</span>
                          <Icons.Copy />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <div className={Css.row}>
                <div className={Css.col}>
                  <FormLabel>{uiTexts.type}</FormLabel>
                  <Select
                    placeholder={uiTexts.selectType}
                    disabled={!editData && !files.length}
                    invalid={!!files.length && !type}
                    value={type}
                    options={typeOptions}
                    onChange={handleTypeChange} />
                </div>
              </div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <FormLabel>{uiTexts.fromDate}</FormLabel>
                  <DatePickerInput
                    portal
                    value={fromDate}
                    disabled={!editData && !files.length}
                    invalid={type === BANK_STATEMENT && !fromDate}
                    placeholder={uiTexts.enterDate}
                    onChange={handleFromDateChange} />
                </div>
                <div className={Css.col}>
                  <FormLabel>{uiTexts.toDate}</FormLabel>
                  <DatePickerInput
                    portal
                    value={toDate}
                    disabled={!editData && !files.length}
                    invalid={type === BANK_STATEMENT && !fromDate}
                    placeholder={uiTexts.enterDate}
                    onChange={handleToDateChange} />
                </div>
              </div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <FormLabel>{uiTexts.description}</FormLabel>
                  <Input
                    multiline
                    placeholder={uiTexts.enterDescription}
                    disabled={!editData && !files.length}
                    value={description}
                    onChange={handleDescriptionChange} />
                </div>
              </div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <FormLabel>{uiTexts.tags}</FormLabel>
                  <TagsInput
                    placeholder={uiTexts.addTags}
                    disabled={!editData && !files.length}
                    value={tags}
                    onChange={handleTagsChange} />
                </div>
              </div>
              {!!editData && (
                <div className={Css.row}>
                  <div className={Css.col} ref={activitySectionRef}>
                    <FormLabel>{uiTexts.activity}</FormLabel>
                    <Activity
                      dataLoadedInitial
                      itemId={editData.id}
                      type={DataConstants.COMMENT_TARGET_TYPES.ARCHIVE}
                      commentsCount={editData.comments} />
                  </div>
                </div>
              )}
            </>
          )
          : <Preloader />}
      </SideBarContent>
      <SideBarFooter>
        <Button
          large outline
          onClick={handleCloseButtonClick}>
          {uiTexts.cancel}
        </Button>
        <Button
          large primary
          disabled={!checkFormValidity() || (editData && !hasChanges)}
          onClick={handleAddButtonClick}>
          {editData ? uiTexts.save : uiTexts.add}
        </Button>
      </SideBarFooter>
    </SideBar>
  );
};

export default React.memo(Sidebar);
