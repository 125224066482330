import Css from "./style.module.scss";

import fallbackLogoSrc from "assets/business.svg";

import * as Icons from "@phosphor-icons/react";
import { AutoTooltip } from "nlib/ui";
import { checkIsBusinessUser, getUserRestrictions } from "selectors/user";
import { getActiveOrganization, getAllUsersData, getProjectName } from "selectors/organizations";
import { getSelectedBusinessId, getUserBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ActionsCell from "./lib/ActionsCell";
import BusinessStatus from "nlib/common/BusinessStatus";
import Businesses from "utils/Businesses";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import DebounceInput from "nlib/ui/DebounceInput";
import GoogleBackupButton from "nlib/common/GoogleBackupButton";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import NoDataContent from "nlib/common/NoDataContent";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectIntegrationService from "nlib/common/SelectIntegrationService";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const searchString = (string, substring) => {
  if (!string || !substring) return false;

  return string.trim().toLocaleLowerCase().includes(substring.trim().toLocaleLowerCase());
};

const BusinessesTab = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [{ search = "", service: searchService, sortBy, sortOrder }, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const allUsersData = useSelector(getAllUsersData);

  const userRestrictions = useSelector(getUserRestrictions);

  const businessUser = useSelector(checkIsBusinessUser);

  const activeOrganization = useSelector(getActiveOrganization);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const userBusinessesData = useSelector(getUserBusinessesData);

  const projectName = useSelector(getProjectName);

  const [searchStr, setSearchStr] = useState(search);

  const showResetFiltersButton = [search, searchService].some(Boolean);

  const preparedData = useMemo(() => {
    return userBusinessesData
      .map((business) => {
        return {
          ...business,
          users: allUsersData
            .filter(({ businessIds = [] }) => !businessIds.length || businessIds.includes(business.id))
        };
      });
  }, [userBusinessesData, allUsersData]);

  const filteredData = useMemo(() => {
    return preparedData.filter((data) => {
      const { name, legalName, users, extraData: { integrationService } = {} } = data;

      if (searchService && searchService !== integrationService) return false;
      if (search
        && !searchString(name, search)
        && !searchString(legalName, search)
        && !users.some(({ fullName }) => searchString(fullName, search))) {
        return false;
      }

      return true;
    });
  }, [preparedData, searchService, search]);

  const tableData = useMemo(() => {
    return Utils.arraySort(filteredData, (item) => {
      if (!item) return -1;

      switch (sortBy) {
        case "service":
          return item.extraData?.integrationService;
        case "documents":
          return item.settings?.advancedDocumentsWorkflow ? 0 : 1;
        default:
          return item[sortBy] || "";
      }
    }, sortOrder === "asc");
  }, [filteredData, sortOrder, sortBy]);

  const handleSearchChange = useCallback((value) => {
    setEnvVars({ search: value || null });
  }, [setEnvVars]);

  const handleSelectServiceChange = useCallback((value) => {
    setEnvVars({ service: value || null });
  }, [setEnvVars]);

  const handleSortChange = useCallback((value) => {
    setEnvVars(value);
  }, [setEnvVars]);

  const handleResetFiltersClick = useCallback(() => {
    setEnvVars({ search: null, service: null });
  }, [setEnvVars]);

  const openSettings = useCallback(async(businessId) => {
    const result = await dispatch(UiActions.showEditBusinessSettingsWindow({ businessId }));

    if (result) {
      dispatch(BusinessesActions.editBusinessSettings(businessId, result));
    }
  }, [dispatch]);

  const handleOpenSettings = useCallback((businessId) => {
    const businessData = userBusinessesData.find(({ id }) => id === businessId);

    if (businessData) {
      const { extraData, settings } = businessData;

      if (Businesses.checkIsOnboarding(extraData, settings)) {
        history.push(`/${businessId}`);
      } else {
        openSettings(businessId);
      }
    }
  }, [userBusinessesData, history, openSettings]);

  const handleDeleteBusiness = useCallback(async(businessId) => {
    const modalResult = await dispatch(UiActions.showModal(messages.actionConfirm, null, true));

    if (modalResult) {
      const result = await dispatch(BusinessesActions.deleteBusiness(businessId));

      if (result) {
        if (businessId === selectedBusinessId) window.location.reload();
        else {
          await dispatch(BusinessesActions.fetchBusinessesList(false, false));
          if (selectedBusinessId) await dispatch(BusinessesActions.fetchBusiness(selectedBusinessId, false));
        }
      }
    }
  }, [dispatch, messages.actionConfirm, selectedBusinessId]);

  const handleTableRowClick = useCallback((event) => {
    if (!event.target.closest("a") && event.currentTarget.dataset.id) {
      handleOpenSettings(event.currentTarget.dataset.id);
    }
  }, [handleOpenSettings]);

  const handleShowAddBusinessModal = useCallback(() => {
    dispatch(UiActions.showAddBusinessWindow());
  }, [dispatch]);

  const handleActionClick = useCallback((event, businessId) => {
    history.push(`/${businessId}`);
  }, [history]);

  const handleReconnectClick = useCallback(async(event, businessId) => {
    event.stopPropagation();

    const businessData = userBusinessesData.find(({ id }) => id === businessId);

    if (businessData) {
      const { extraData: { integrationService } } = businessData;

      const quickBooksDesktopBusiness = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

      if (quickBooksDesktopBusiness) {
        history.push(`/${businessId}`);
      } else {
        const consentUrl = await dispatch(IntegrationsActions.buildConsentUrl(integrationService));

        if (consentUrl) window.location.href = consentUrl;
      }
    }
  }, [dispatch, history, userBusinessesData]);

  useEffect(() => {
    setSearchStr(search || "");
  }, [search]);

  return (
    <div className={Css.businessesTab}>
      <div className={classNames(Css.actions, showResetFiltersButton && Css.sticky)}>
        <div className={Css.filters}>
          <DebounceInput
            cleanable
            iconBefore={Icons.MagnifyingGlass}
            placeholder={messages.searchBusinessPlaceholder}
            className={Css.text}
            value={searchStr}
            onChange={setSearchStr}
            onInputComplete={handleSearchChange} />
          <SelectIntegrationService
            filter
            value={searchService}
            className={Css.select}
            onChange={handleSelectServiceChange} />
          {showResetFiltersButton && (
            <Button
              danger large outline
              icon={Icons.X}
              className={Css.button}
              onClick={handleResetFiltersClick}>
              {uiTexts.resetFilters}
            </Button>
          )}
        </div>
        <GoogleBackupButton />
        <Button
          large primary
          className={Css.button}
          icon={Icons.Plus}
          onClick={handleShowAddBusinessModal}>
          {uiTexts.addNewBusiness}
        </Button>
      </div>
      {tableData.length
        ? (
          <Table
            className={Css.table}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSortChange={handleSortChange}>
            <TableRow>
              <TableHead className={Css.nameCell} accessor="name">{uiTexts.displayName}</TableHead>
              <TableHead className={Css.nameCell} accessor="legalName">{uiTexts.legalName}</TableHead>
              <TableHead className={Css.usersCell} accessor="users">{uiTexts.users}</TableHead>
              <TableHead className={Css.documentsCell} accessor="documents">{uiTexts.documentsWorkflow}</TableHead>
              <TableHead className={Css.actionsCell}>{uiTexts.actions}</TableHead>
            </TableRow>
            {tableData.map((item) => {
              const {
                id: businessId,
                name,
                legalName,
                users,
                extraData,
                extraData: { integrationService } = {},
                settings,
                settings: { advancedDocumentsWorkflow } = {}
              } = item;

              const { logo: serviceLogo, label: serviceName } = IntegrationServices.getByValue(integrationService)
                || { logo: fallbackLogoSrc, label: projectName };

              return (
                <TableRow
                  key={businessId}
                  data-id={businessId}
                  className={Css.tableRow}
                  onClick={handleTableRowClick}>
                  <TableCell className={Css.nameCell}>
                    <img title={serviceName} alt={serviceName} src={serviceLogo} />
                    <div>
                      <div title={name}>{name}</div>
                      <BusinessStatus
                        businessId={businessId}
                        extraData={extraData}
                        settings={settings}
                        onActionClick={handleActionClick}
                        onReconnectClick={handleReconnectClick} />
                    </div>
                  </TableCell>
                  <TableCell className={Css.nameCell}>
                    <div>{legalName}</div>
                  </TableCell>
                  <TableCell className={Css.usersCell}>
                    <AutoTooltip title={users.map(({ fullName }) => fullName).join(", ")}>
                      {users.map(({ fullName }) => fullName).join(", ")}
                    </AutoTooltip>
                  </TableCell>
                  <TableCell className={Css.documentsCell}>
                    <div>
                      {advancedDocumentsWorkflow === undefined
                        ? Constants.EMPTY_PLACEHOLDER
                        : (advancedDocumentsWorkflow ? uiTexts.advanced : uiTexts.simple)}
                    </div>
                  </TableCell>
                  <ActionsCell
                    businessId={businessId}
                    className={Css.actionsCell}
                    disabledDelete={userRestrictions.businessesDelete
                      || businessId === activeOrganization.businessId}
                    disabledOpenSettings={businessUser}
                    onOpenSettings={handleOpenSettings}
                    onDelete={handleDeleteBusiness} />
                </TableRow>
              );
            })}
          </Table>
        )
        : (
          <div className={Css.emptyState}>
            <NoDataContent
              icon={Icons.Suitcase}
              title={uiTexts.noBusinessesFound} />
          </div>
        )}
    </div>
  );
};

export default React.memo(BusinessesTab);
