export default class MainApiRoutes {
  static ACCOUNTS = "/accounts";

  static ACTIVATE = "/activate";

  static ACTIVITY = "/activity";

  static ALL_INACTIVE = "/all-inactive";

  static ARCHIVE = "/archive";

  static ASK_BUSINESS = "/ask-business";

  static ATTACHMENTS = "/attachments";

  static AUDIT = "/audit";

  static BACKUP_INFO = "/backup-info";

  static BANKS = "/banks";

  static BILLING_PORTAL = "/billing-portal";

  static BULK = "/bulk";

  static BUSINESSES = "/businesses";

  static CATEGORIES = "/categories";

  static CHECKOUT_SESSION = "/checkout-session";

  static CLIENT_NAME = "/client-name";

  static CLONE = "/clone";

  static COMMENTS = "/comments";

  static CONNECTION_COMPLETE = "/connection-complete";

  static CONNECTION_INIT = "/connection-init";

  static CONNECTION_URL = "/connection-url";

  static CONSENT_URL = "/consent-url";

  static CURRENCIES = "/currencies";

  static DASHBOARD = "/dashboard";

  static DELETE = "/delete";

  static DOCUMENTS = "/documents";

  static DOWNLOAD = "/download";

  static EVENTS = "/events";

  static EXCHANGE_CODE = "/exchange-code";

  static EXECUTIVE_SUMMARY = "/executive-summary";

  static EXPIRATION_INFO = "/expiration-info";

  static EXPORT = "/export";

  static EXTRA = "/extra";

  static FULLFILL = "/fullfill";

  static GOOGLE = "/google";

  static IMPORT = "/import";

  static INBOX = "/inbox";

  static INSTITUTIONS = "/institutions";

  static INTEGRATE = "/integrate";

  static INTEGRATIONS = "/integrations";

  static INVITE = "/invite";

  static INVOICE = "/invoice";

  static LINKS = "/links";

  static LOOKUP = "/lookup";

  static MARK_ALL = "/mark-all-read";

  static MATCHES = "/matches";

  static MERGE = "/merge";

  static META = "/meta";

  static ORGANIZATIONS = "/organizations";

  static PAIR = "/pair";

  static PRE_ACCOUNTING = "/pre-accounting";

  static PRICES = "/prices";

  static REASONS = "/reasons";

  static REPORTS = "/reports";

  static REVISED = "/revised";

  static REVOKE = "/revoke";

  static RPA = "/rpa";

  static RPA_DETAILS = "/rpa-details";

  static SETTINGS = "/settings";

  static SETUP = "/setup";

  static SOURCES = "/sources";

  static START = "/start";

  static STATS = "/stats";

  static STOP_VALIDATION = "/stop-validation";

  static STRIPE = "/stripe";

  static SUMMARY = "/summary";

  static SYNC_ACCOUNTS = "/sync-accounts";

  static SYNC_CLASSES = "/sync-classes";

  static SYNC_ITEMS = "/sync-items";

  static SYNC_LOCATIONS = "/sync-locations";

  static SYNC_PROJECTS = "/sync-projects";

  static SYNC_TAX_RATES = "/sync-tax-rates";

  static SYNC_TRANSACTIONS = "/sync-transactions";

  static SYNC_VENDORS = "/sync-vendors";

  static TAGS = "/tags";

  static TASKS = "/tasks";

  static TOGGLE_MFA = "/toggle-mfa";

  static TRANSACTIONS = "/transactions";

  static UNPAIR = "/unpair";

  static UNREAD = "/unread";

  static USAGE_STATS = "/usage-stats";

  static USERS = "/users";

  static VALIDATION = "/validation";

  static VAT_CLASSIFICATIONS = "/vat-classifications";

  static VENDORS = "/vendors";
}
